<ion-header
  class="ion-no-border simple-action-modal_header"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="
        (platformService?.isTouchExperience$ | async) === false &&
        platformService?.platformType === platformService?.platformEnum?.desktop
      "
      slot="end"
    >
      <ion-button
        id="close-modal-button"
        data-cy="simple-action-close-modal-icon"
        (click)="dismiss()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  data-cy="modal-prompt-area-text"
  class="simple-action-modal_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div
    class="content"
    [ngClass]="
      (platformService?.isTouchExperience$ | async)
        ? platformService?.platformType ===
          platformService?.platformEnum?.tablet
          ? 'ion-content-modal-tablet'
          : 'ion-content-modal-mobile'
        : 'ion-content-modal-desktop'
    "
  >
    <ng-container *ngIf="!customerSwitch; else customerSwitchContent">
      <img
        src="../../../../../assets/images/order-syncing.png"
        class="alert-icon-border"
        data-cy="message-success-image"
      />
      <div class="info-text">
        <h3 data-cy="order-syncing-title">
          {{ 'i18n.orderPage.orderSyncingModal.stillSyncing' | translate }}
        </h3>
        <p *ngIf="!orderSwitch" data-cy="order-syncing-description-text">
          {{ 'i18n.orderPage.orderSyncingModal.pleaseWaitSubmit' | translate }}
        </p>
        <p *ngIf="orderSwitch" data-cy="order-syncing-description-text">
          {{
            'i18n.orderPage.orderSyncingModal.pleaseWaitTryAgain' | translate
          }}
        </p>
      </div></ng-container
    >
    <ng-template class="info-text" #customerSwitchContent>
      <img
        src="../../../../../assets/images/alert-orange.svg"
        class="alert-icon-border customer-switch"
        data-cy="app-error-image"
      />
      <div class="info-text">
        <h3 data-cy="order-syncing-title">
          {{ 'i18n.orderPage.orderSyncingModal.areYouSure' | translate }}
        </h3>
        <p data-cy="order-syncing-description-text">
          {{
            'i18n.orderPage.orderSyncingModal.switchCustomersWarning'
              | translate
          }}
        </p>
      </div>
    </ng-template>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ng-container
        *ngIf="!orderSwitch && !customerSwitch; else continueAndCloseButtons"
      >
        <ion-button
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-outline-green-dsktp-tblt-modal-button'
              : 'usf-outline-green-mobile-modal-button'
          "
          (click)="dismiss()"
          data-cy="order-syncing-continue-button"
        >
          {{ 'i18n.common.close' | translate }}
        </ion-button></ng-container
      >
      <ng-template #continueAndCloseButtons>
        <ion-button
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-outline-green-dsktp-tblt-modal-button'
              : 'usf-outline-green-mobile-modal-button'
          "
          (click)="continueClicked()"
          data-cy="order-syncing-continue-button"
        >
          {{ 'i18n.common.continue' | translate }}
        </ion-button>
        <ion-button
          [ngClass]="
            platformService?.platformType !==
            platformService?.platformEnum?.mobile
              ? 'usf-fill-green-dsktp-tblt-modal-button'
              : 'usf-fill-green-mobile-modal-button'
          "
          (click)="dismiss()"
          data-cy="order-syncing-cancel-button"
        >
          {{ 'i18n.common.cancel' | translate }}
        </ion-button>
      </ng-template>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
