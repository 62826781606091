import { SeperatedColumn } from '@shared/models/seperated-columns';
import {
  ListColumns,
  productConversionColumns,
} from './document-helper-models-and-enums';
import { desiredColumn } from '@shared/models/desired-column';
import { DownloadListOptions } from '@usf/ngrx-list';
import { ListTypes } from '../../../constants/lists-constants';
/**
 *
 * @param seperatedColumns Contains List Data
 * @param numberOfRows The Number of Data Rows
 * @param seperator
 * @returns
 */
export const stringifyCSV = (
  seperatedColumns: SeperatedColumn[],
  numberOfRows: number,
  seperator: any,
): string => {
  // Variable to hold the seperated columns as one string
  let stringified = '';
  // Create the Header Row
  const headerRow = seperatedColumns
    .map(pMap => pMap.columnName)
    .join(seperator);
  // Add the header row to return string
  stringified += headerRow + '\r\n';
  // Add every row of data to return string
  for (let i = 0; i < numberOfRows; i++) {
    const line = seperatedColumns
      .map(pm => (pm.columnValues ? pm.columnValues[i] : pm.singleValue))
      .join(seperator);
    stringified += line + '\r\n';
  }
  return stringified;
};

export const getCSVColumns = (
  downloadListOptions: DownloadListOptions,
): desiredColumn[] => {
  const seperatedColumns = [];
  const isRecentPurchase =
    downloadListOptions.listTypeId === ListTypes.recentlyPurchased;
  if (!isRecentPurchase) {
    seperatedColumns.push({
      columnName: 'Line Number',
      columnType: ListColumns.lineNumber,
    });
    seperatedColumns.push({
      columnName: 'Group Name',
      columnType: ListColumns.groupName,
    });
  }

  seperatedColumns.push({
    columnName: 'Product Number',
    columnType: ListColumns.productNumber,
  });
  if (downloadListOptions.includeProductStatus)
    seperatedColumns.push({
      columnName: 'Product Status',
      columnType: ListColumns.productStatus,
    });
  seperatedColumns.push({
    columnName: 'Product Description',
    columnType: ListColumns.productDescription,
  });
  seperatedColumns.push({
    columnName: 'Product Brand',
    columnType: ListColumns.productBrand,
  });
  seperatedColumns.push({
    columnName: 'Product Package Size',
    columnType: ListColumns.productPackageSize,
  });
  seperatedColumns.push({
    columnName: 'Customer Product Number',
    columnType: ListColumns.customerProductNumber,
  });
  if (downloadListOptions.includeProductPrices) {
    seperatedColumns.push({
      columnName: 'Product Price',
      columnType: ListColumns.productPriceWithZero,
    });
    seperatedColumns.push({
      columnName: 'Product UOM',
      columnType: ListColumns.productUom,
    });
  }
  if (downloadListOptions.includeProductType)
    seperatedColumns.push({
      columnName: 'Product Type',
      columnType: ListColumns.productType,
    });
  if (downloadListOptions.includeProductNotes)
    seperatedColumns.push({
      columnName: 'Product Note',
      columnType: ListColumns.productNote,
    });

  seperatedColumns.push({
    columnName: 'USF Class Description',
    columnType: ListColumns.usFoodsClassDescription,
  });

  seperatedColumns.push({
    columnName: 'Storage Description',
    columnType: ListColumns.storageDescription,
  });
  if (downloadListOptions.includeMasterListAssociation) {
    seperatedColumns.push({
      columnName: 'On ML',
      columnType: ListColumns.onML,
    });
  }

  if (downloadListOptions.includeNutritionals) {
    seperatedColumns.push(
      ...[
        {
          columnName: 'SERVING SIZE',
          columnType: productConversionColumns.servingSize,
        },
        {
          columnName: 'SERVING SIZE UOM',
          columnType: productConversionColumns.ServingUOM,
        },
        {
          columnName: 'SERVINGS PER TRADE ITEM',
          columnType: productConversionColumns.servingPerTrade,
        },
        {
          columnName: 'CALORIES',
          columnType: productConversionColumns.calories,
        },
        {
          columnName: 'CALORIES FROM FAT (g)',
          columnType: productConversionColumns.caloriesFat,
        },
        {
          columnName: 'TOTAL FAT (g)',
          columnType: productConversionColumns.totalFat,
        },
        {
          columnName: 'SATURATED FAT (g)',
          columnType: productConversionColumns.satFat,
        },
        {
          columnName: 'TRANS FATTY ACIDS (g)',
          columnType: productConversionColumns.transFat,
        },
        {
          columnName: 'CHOLESTEROL (mg)',
          columnType: productConversionColumns.cholest,
        },
        {
          columnName: 'SODIUM (mg)',
          columnType: productConversionColumns.sodium,
        },
        {
          columnName: 'TOTAL CARBOHYDRATES (g)',
          columnType: productConversionColumns.totalCarb,
        },
        {
          columnName: 'DIETARY FIBER (g)',
          columnType: productConversionColumns.dietFiber,
        },
        {
          columnName: 'TOTAL SUGAR (g)',
          columnType: productConversionColumns.totalSug,
        },
        {
          columnName: 'PROTEIN (g)',
          columnType: productConversionColumns.protein,
        },
        {
          columnName: 'CHILD NUTRITION CERTIFICATION',
          columnType: productConversionColumns.childNutrition,
        },
        {
          columnName: 'CHOLESTEROL FREE',
          columnType: productConversionColumns.cholestFree,
        },
        {
          columnName: 'FAT FREE',
          columnType: productConversionColumns.fatFree,
        },
        {
          columnName: 'LOW FAT',
          columnType: productConversionColumns.lowFat,
        },
        {
          columnName: 'MSG FREE',
          columnType: productConversionColumns.msgFree,
        },
        {
          columnName: 'NO SUGAR ADDED',
          columnType: productConversionColumns.noSug,
        },
        {
          columnName: 'TRANS FAT FREE',
          columnType: productConversionColumns.transFatFree,
        },
        {
          columnName: 'LOCALLY SOURCED',
          columnType: productConversionColumns.localSource,
        },
        {
          columnName: 'KOSHER',
          columnType: productConversionColumns.kosher,
        },
        {
          columnName: 'HALAL',
          columnType: productConversionColumns.halal,
        },
        {
          columnName: 'RECYCLABLE',
          columnType: productConversionColumns.recycle,
        },
        {
          columnName: 'CERTIFIED ANGUS BEEF',
          columnType: productConversionColumns.certBeef,
        },
        {
          columnName: 'ORGANIC',
          columnType: productConversionColumns.organic,
        },
        {
          columnName: 'ADDITIONAL INFO',
          columnType: productConversionColumns.addInfo,
        },
        {
          columnName: 'INGREDIENTS STATEMENT',
          columnType: productConversionColumns.ingStatement,
        },
      ],
    );
  }
  return seperatedColumns;
};
