<ion-item
  [attr.data-cy]="'super-user-customer-option-' + customerOptionIndex"
  [class.department]="customer?.isDepartment"
  [class.mobile]="platform === platformEnum.mobile"
>
  <!-- Customers w/ NO Departments, Departments -->
  <ion-radio
    *ngIf="
      !customer?.hasDepartments || customer?.isDepartment;
      else customerWithDepts
    "
    labelPlacement="start"
    mode="md"
    [value]="customer"
    [attr.data-cy]="
      'super-user-customer-option-radio-button-' + customerOptionIndex
    "
    [ngClass]="{
      'favorite-flag': (superUserFavoriteCustomerFeatureFlag$ | async),
    }"
  >
    <ng-container [ngTemplateOutlet]="customerOption"></ng-container>
  </ion-radio>

  <!-- Customers w/ Departments  -->
  <ng-template #customerWithDepts>
    <ng-container [ngTemplateOutlet]="customerOption"></ng-container>
  </ng-template>
</ion-item>

<ng-template #customerOption>
  <div
    [attr.data-cy]="
      'super-user-customer-option-radio-content-' + customerOptionIndex
    "
    class="super-user-radio-content"
  >
    <div
      *ngIf="!customer?.isDepartment"
      [attr.data-cy]="
        'super-user-customer-option-profile-icon-' + customerOptionIndex
      "
      class="super-user-profile-icon"
    >
      {{ customer?.firstLetter }}
    </div>
    <div
      [attr.data-cy]="'super-user-customer-option-title-' + customerOptionIndex"
      class="super-user-customer-info"
    >
      {{ customer?.title }}
      <p
        [attr.data-cy]="
          'super-user-customer-option-subtitle-' + customerOptionIndex
        "
      >
        {{ customer?.subtitle }}
      </p>
      <ng-content></ng-content>
    </div>
  </div>
</ng-template>
