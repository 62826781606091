import { Injectable } from '@angular/core';
import { CoveoService } from '../coveo/coveo.service';
import {
  SpecificFacetSearchResult,
  Suggestion,
  Unsubscribe,
} from '@coveo/headless';
import {
  buildInstantProducts,
  FieldSuggestionsGenerator,
  InstantProducts,
  Product,
  buildFieldSuggestionsGenerator,
  GeneratedFieldSuggestionsControllers,
  FieldSuggestionsState,
  StandaloneSearchBox,
  buildStandaloneSearchBox,
  FieldSuggestions,
  InteractiveProduct
} from '@coveo/headless/commerce';
import { filter, take } from 'rxjs';
import { AcronymTitleCasePipe } from '@shared/pipes/acronym-title-case.pipe';
import { SuggestionTypeAheadItem, TypeaheadProduct } from '@app/new-search/models/new-search-typeahead.model';
import { highlightText, titleCase} from '@app/new-search/utils/new-search-typeahead.util';
import { ProductAlternativeSearchService } from '@product-detail/services/product-alternative-search.service';

@Injectable({
  providedIn: 'root',
})
export class TypeaheadService {
  constructor(private coveoService: CoveoService,
    public acronymTitleCasePipe: AcronymTitleCasePipe,
  ) { }

  fieldSuggestionsGenerator: FieldSuggestionsGenerator;
  instantProductsController: InstantProducts;
  searchBoxController: StandaloneSearchBox;
  fieldSuggestionControllers: GeneratedFieldSuggestionsControllers;
  fieldSuggestionsGeneratorController: FieldSuggestionsGenerator;
  fieldSuggestionsState!: FieldSuggestionsState;
  private searchBoxId = 'header-search-box';
  fieldSuggestionController: FieldSuggestions;
  searchBoxSubscription!: Unsubscribe;


  initializeTypeAhead(
    newProductsTypeahead: TypeaheadProduct[],
    brandsTypeahead: SpecificFacetSearchResult[],
    suggestedSearchTypeahead: SuggestionTypeAheadItem[],
  ) {
    this.coveoService.commerceEngineSubscription$ = this.coveoService
      .getCommerceEngine$()
      .pipe(take(1), filter(engine => !!engine))
      .subscribe(engine => {
        this.searchBoxController = buildStandaloneSearchBox(
          engine,
          {
            options: {
              id: this.searchBoxId,
              highlightOptions: {
                exactMatchDelimiters: { open: '<strong>', close: '</strong>' },
              },
              redirectionUrl: '/new-search'
            },
          },
        );
        this.fieldSuggestionsGeneratorController =
          buildFieldSuggestionsGenerator(engine);

        this.instantProductsController = buildInstantProducts(
          engine,
          {
            options: {
              searchBoxId: this.searchBoxId,
            },
          },
        );
        this.searchBoxController.showSuggestions();

        this.searchBoxSubscription = this.searchBoxController.subscribe(() => {
          const suggestions: Suggestion[] =
            this.searchBoxController.state.suggestions;
          if (suggestions?.length) {
            suggestedSearchTypeahead.length = 0;
            suggestions
              .slice(0, 5)
              .map(suggestion =>
                suggestedSearchTypeahead.push({
                  ...suggestion,
                  displayValue: suggestion.rawValue,
                  highlightedValue: titleCase(suggestion.highlightedValue),
                }));
          }

          this.fieldSuggestionControllers =
            this.fieldSuggestionsGeneratorController.fieldSuggestions;

          this.fieldSuggestionControllers.forEach(
            fieldSuggestionControllerParam => {
              this.fieldSuggestionController = fieldSuggestionControllerParam as FieldSuggestions;
              this.fieldSuggestionController.subscribe(() => {
                brandsTypeahead.length = 0;
                this.fieldSuggestionsState =
                  this.fieldSuggestionController.state;
                this.fieldSuggestionsState.values
                  .slice(0, 5)
                  .map(fieldSuggestion =>
                    brandsTypeahead.push({
                      ...fieldSuggestion,
                      rawValue: this.acronymTitleCasePipe.transform(fieldSuggestion.rawValue)
                    }),
                  );
              });
            },
          );
        });
         
        this.instantProductsController.subscribe(() => {
          newProductsTypeahead.length = 0;
          const products: Product[] =
            this.instantProductsController.state.products;
          if (products?.length) {
            products.slice(0, 9)
            .map(prod =>
             newProductsTypeahead.push({
              ...prod,
              productName: highlightText(prod.ec_name, prod.nameHighlights[0].offset, prod.nameHighlights[0].length),
             }),
            );
          }
        });
      });
  }

  selectSearchTermSuggetion(searchTerm: string) {
    this.searchBoxController.selectSuggestion(searchTerm);
  }

  selectBrandSuggetion(brand: SpecificFacetSearchResult) {
    this.fieldSuggestionController.singleSelect(brand);
  }

  selectInstantProduct(product: Product) {
    const interactiveProduct : InteractiveProduct = this.instantProductsController.interactiveProduct({
      options: {
        product: product
      }
    });
    interactiveProduct.select();
  }
}