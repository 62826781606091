import { Component, OnInit } from '@angular/core';
import { IonNav, ModalController } from '@ionic/angular';
import { PlatformService } from '@panamax/app-state';
import { ListDetailManagementService } from '../../../list-detail-management/services/list-detail-management.service';
import { SelectedProductsBaseComponent } from '../../selected-products-base.component';
import { GroupListInputComponent } from '../group-list-input/group-list-input.component';
import { TranslateService } from '@ngx-translate/core';
import { ListActions } from '@shared/constants/lists-constants';
import { CopyToListsModalComponent } from '@app/lists/components/copy-to-lists-modal/copy-to-lists-modal.component';
import { ListKey } from '@usf/list-types';
@Component({
  selector: 'app-selected-products-input',
  templateUrl: './selected-products-input.component.html',
  styleUrls: ['./selected-products-input.component.scss'],
})
export class SelectedProductsInputComponent
  extends SelectedProductsBaseComponent
  implements OnInit
{
  title = '';
  listActions: ListActions;
  showCopyToList: boolean;

  constructor(
    nav: IonNav,
    modalController: ModalController,
    platformService: PlatformService,
    listDetailManagementService: ListDetailManagementService,
    readonly translateService: TranslateService,
  ) {
    super(nav, modalController, platformService, listDetailManagementService);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.title =
      this.selectedProducts.length === 1
        ? this.translateService.instant(
            'i18n.lists.copyProductsModal.copyProductHeader',
            { amount: this.selectedProducts.length },
          )
        : this.translateService.instant(
            'i18n.lists.copyProductsModal.copyProductsHeader',
            { amount: this.selectedProducts.length },
          );
    this.showCopyToList =
      await this.listDetailManagementService.showCopyToList();
  }

  copyToDifferentGroup() {
    this.nav.push(GroupListInputComponent, {
      list: this.list,
      selectedAction: this.selectedAction,
      selectedProducts: this.selectedProducts,
      groups: this.groups,
      selectedGroup: this.selectedGroup,
      listItems: this.listItems,
      useNewCopyProduct: this.useNewCopyProduct,
      title: this.title,
    });
  }

  copySelection(event) {
    this.nav.push(CopyToListsModalComponent, {
      platform: this.platformService.platformType,
      dismissFunc: () => {
        this.dismissModal();
      },
      getListData: async () => {
        return await this.listDetailManagementService.requestLoadAndGiveAccessibleListData$();
      },
      getListsLoaded: () => {
        return this.listDetailManagementService.getIsAccessibleLoaded$();
      },
      title: this.title,
      selectedProducts: this.selectedProducts,
      finalSubmission: (
        targetListKeys: ListKey[],
        productNumbers: number[],
      ) => {
        return this.listDetailManagementService.submitCopyToListData(
          targetListKeys,
          productNumbers,
        );
      },
    });
  }
}
