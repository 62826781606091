import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { PlatformService } from '@panamax/app-state';
import { getGlCodeForNonUsfProduct } from '@shared/selectors/helpers/product-info.selectors.helper';
import { selectGeneralLedgerState, selectGlPimDictionary } from '@usf/ngrx-gl';
import { IGeneralLedgerState } from '@usf/ngrx-gl/lib/reducers/gl.reducer';
import {
  allExternalVendors,
  allNonUSFProducts,
  ExternalVendorState,
  getKeyword,
  getNonUSFProductSelections,
  getNonUSFSortOptions,
  NonUsfProductSortOptions,
  SortOptionsEnum,
} from '@usf/ngrx-product';
import { GeneralLedger } from '@usf/product-types';
import { Observable } from 'rxjs';
import { selectCurrentCustomerDepartmentNumberNameMap } from '../../../../ngrx-customer/store';
import { stringifyCsvData } from '../../../../shared/helpers/document-creation.helpers';
import { SeperatedColumn } from '../../../../shared/models/seperated-columns';
import { DownloadableGlCode } from '../models/download-gl-code.model';
import {
  manageNonUsfProductsViewModel,
  NonUSFProductViewModel,
} from '../models/non-usf-products-view-model';
import { transformNonUsfProductToProduct } from '../transformers/non-usf-products-transformers';
import { calculateVSHeights } from './helpers/manage-non-usf-products-virtual-scroll-helper';

@Injectable({
  providedIn: 'root',
})
export class NonUsfProductViewModelSelectors {
  constructor(
    private platformService: PlatformService,
    private store: Store,
  ) {}

  private selectFilteredNonUsfProductsPartial$ = createSelector(
    allNonUSFProducts(),
    getNonUSFProductSelections(),
    allExternalVendors(),
    getKeyword(),
    (productsMap, selections, vendors, keyword) => {
      let products: NonUSFProductViewModel[] = [];
      let totalNonUsfProducts = 0;
      productsMap.forEach(product => {
        if (product !== undefined) {
          totalNonUsfProducts++;
          const vendorFound: ExternalVendorState = vendors.find(vendor => {
            return vendor.vendorId === product.vendorId;
          });
          const nonUsfProduct: NonUSFProductViewModel = {
            ...product,
            isSelected:
              selections[product?.productNumber ?? 999999999999999] ?? false,
            vendorName:
              vendorFound !== undefined ? vendorFound.distributorName : '',
          };
          if (this.nonUsfProductPassesFilter(nonUsfProduct, keyword)) {
            products.push(nonUsfProduct);
          }
        }
      });
      return {
        products,
        vendors,
        totalNonUsfProducts,
      } as manageNonUsfProductsViewModel;
    },
  );

  private selectSortedNonUsfProductsPartial$ = createSelector(
    this.selectFilteredNonUsfProductsPartial$,
    getNonUSFSortOptions(),
    (partialViewModel, sortOptions) => {
      const products = this.sortNonUsfProducts(
        partialViewModel.products,
        sortOptions,
      );
      const itemHeights = calculateVSHeights(
        partialViewModel.products,
        this.platformService.platformType,
      );
      return {
        ...partialViewModel,
        products,
        sortOptions,
        itemHeights,
      } as manageNonUsfProductsViewModel;
    },
  );

  private _manageNonUSFProductViewModel$: Observable<manageNonUsfProductsViewModel> =
    this.store.select(
      createSelector(
        this.selectSortedNonUsfProductsPartial$,
        selectGeneralLedgerState,
        (partialViewModel, generalLedgerState) => {
          return {
            ...partialViewModel,
            showDownloadGlCode: generalLedgerState.ids.length > 0,
          } as manageNonUsfProductsViewModel;
        },
      ),
    );

  private _glCodeCsv$: Observable<string> = this.store.select(
    createSelector(
      selectCurrentCustomerDepartmentNumberNameMap,
      selectGeneralLedgerState,
      (departmentNumberNameMap, generalLedgerState) => {
        const includeDepartmentInDownload = departmentNumberNameMap.size > 0;
        let downloadableGlCodes: DownloadableGlCode[] =
          this.createDownloadableGlCodes(
            departmentNumberNameMap,
            generalLedgerState,
          );

        downloadableGlCodes = this.sortDownloadableGlCodes(
          downloadableGlCodes,
          includeDepartmentInDownload,
        );

        const separatedColumns = this.createGlCodeSeperatedColumns(
          downloadableGlCodes,
          includeDepartmentInDownload,
        );

        return stringifyCsvData(separatedColumns);
      },
    ),
  );

  public get glCodeCsv$(): Observable<string> {
    return this._glCodeCsv$;
  }

  public get manageNonUSFProductViewModel$(): Observable<manageNonUsfProductsViewModel> {
    return this._manageNonUSFProductViewModel$;
  }

  private nonUsfProductPassesFilter(
    product: NonUSFProductViewModel,
    keyword?: string,
  ): boolean {
    if (!!keyword) {
      const upperKeyWord = keyword.toUpperCase();
      return (
        product?.productDescLong?.toUpperCase().includes(upperKeyWord || '') ||
        product?.vendorName?.toUpperCase().includes(upperKeyWord || '') ||
        product?.brand?.toUpperCase().includes(upperKeyWord || '') ||
        product?.productNumber?.toString().includes(upperKeyWord || '') ||
        product?.manufacturerProductNumber
          ?.toUpperCase()
          .includes(upperKeyWord || '')
      );
    }
    return true;
  }

  private createDownloadableGlCodes(
    departmentNumberNameMap: Map<number, string>,
    generalLedgerState: IGeneralLedgerState,
  ): DownloadableGlCode[] {
    let downloadableGlCodes: DownloadableGlCode[] = [];
    const includeDepartmentInDownload = departmentNumberNameMap.size > 0;
    for (const id of generalLedgerState.ids) {
      const generalLedger: GeneralLedger = generalLedgerState.entities[id];
      const departmentName = includeDepartmentInDownload
        ? departmentNumberNameMap.get(generalLedger.departmentNumber)
        : undefined;
      if (!!generalLedger) {
        const downloadableGlCode: DownloadableGlCode = {
          departmentName,
          glDescription: generalLedger.glDescription,
          glNumber: generalLedger.glCode,
        };
        downloadableGlCodes.push(downloadableGlCode);
      }
    }
    return downloadableGlCodes;
  }

  private sortDownloadableGlCodes(
    downloadableGlCodes: DownloadableGlCode[],
    includeDepartmentInDownload: boolean,
  ): DownloadableGlCode[] {
    return downloadableGlCodes.sort((a, b) => {
      if (
        !includeDepartmentInDownload ||
        a.departmentName?.toLowerCase() === b.departmentName?.toLowerCase()
      ) {
        return a.glDescription.toLowerCase() > b.glDescription.toLowerCase()
          ? 1
          : -1;
      }

      return a.departmentName?.toLowerCase() > b.departmentName?.toLowerCase()
        ? 1
        : -1;
    });
  }

  private createGlCodeSeperatedColumns(
    downloadableGlCodes: DownloadableGlCode[],
    includeDepartmentInDownload: boolean,
  ): SeperatedColumn[] {
    const departmentNameValues = [];
    const glDescriptionValues = [];
    const glNumberValues = [];
    for (const downloadableGlCode of downloadableGlCodes) {
      departmentNameValues.push(downloadableGlCode.departmentName);
      glDescriptionValues.push(downloadableGlCode.glDescription);
      glNumberValues.push(downloadableGlCode.glNumber);
    }
    const separatedColumns: SeperatedColumn[] = [];
    if (includeDepartmentInDownload) {
      const departmentColumn: SeperatedColumn = {
        columnName: 'Department Name',
        columnValues: departmentNameValues,
        totals: '',
      };
      separatedColumns.push(departmentColumn);
    }
    const glDescriptionColumn: SeperatedColumn = {
      columnName: 'GL Description',
      totals: '',
      columnValues: glDescriptionValues,
    };

    const glNumberColumn: SeperatedColumn = {
      columnName: 'GL Number',
      totals: '',
      columnValues: glNumberValues,
    };
    separatedColumns.push(glDescriptionColumn, glNumberColumn);
    return separatedColumns;
  }

  public sortNonUsfProducts = (
    products: NonUSFProductViewModel[],
    sortOptions: NonUsfProductSortOptions,
  ): NonUSFProductViewModel[] => {
    const sortedProducts = products.sort((a, b) => {
      const aSortValue = this.getProductSortValue(
        a,
        sortOptions.selectedHeader,
      );
      const bSortValue = this.getProductSortValue(
        b,
        sortOptions.selectedHeader,
      );
      const sortDirection = this.getSortDirection(sortOptions);
      if (!aSortValue && !!bSortValue) {
        return sortDirection === SortOptionsEnum.desc ? 1 : -1;
      }
      if (!!aSortValue && !bSortValue) {
        return sortDirection === SortOptionsEnum.desc ? -1 : 1;
      }
      if (aSortValue === bSortValue || (!aSortValue && !bSortValue)) {
        return a?.productNumber > b?.productNumber ? -1 : 1;
      }
      if (sortDirection === SortOptionsEnum.desc) {
        return aSortValue > bSortValue ? -1 : 1;
      } else {
        return aSortValue > bSortValue ? 1 : -1;
      }
    });
    return sortedProducts;
  };

  public getProductSortValue = (
    product: NonUSFProductViewModel,
    selectedHeader: string,
  ) => {
    if (selectedHeader === SortOptionsEnum.productName) {
      return product.productDescLong?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.packSize) {
      return product.salesPackSize?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.manufacturerNumber) {
      return product.manufacturerProductNumber?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.brand) {
      return product.brand?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.vendor) {
      return product.vendorName?.toLowerCase();
    } else if (selectedHeader === SortOptionsEnum.pricing) {
      return product.price;
    } else {
      return product.productNumber;
    }
  };

  public getSortDirection = (sortOptions: NonUsfProductSortOptions) => {
    return sortOptions[sortOptions.selectedHeader].sortDirection;
  };

  public getSelectionsArray = () => {
    return createSelector(getNonUSFProductSelections(), selections => {
      return Object.keys(selections).map(value => {
        if (selections[value]) {
          return Number(value).valueOf();
        }
      });
    });
  };

  public getNonUSfProductsBasedOnId = (ids: number[]) => {
    return createSelector(allNonUSFProducts(), productMap => {
      return productMap
        .filter(product => ids.includes(product.productNumber))
        .sort((a, b) => a.productDescLong.localeCompare(b.productDescLong));
    });
  };

  public getNonUSfProductsByIdForDownload = (ids: number[]) => {
    return createSelector(
      allNonUSFProducts(),
      allExternalVendors(),
      selectGlPimDictionary,
      getNonUSFSortOptions(),
      (productsMap, vendors, glPimDictionary, sortOptions) => {
        let products: NonUSFProductViewModel[] = [];
        productsMap
          .filter(product => {
            return ids.includes(product.productNumber);
          })
          .forEach(product => {
            if (!!product) {
              const vendorFound: ExternalVendorState = vendors.find(vendor => {
                return vendor.vendorId === product.vendorId;
              });
              products.push({
                ...product,
                isSelected: false,
                vendorName: vendorFound?.distributorName ?? '',
                glCode: getGlCodeForNonUsfProduct(product, glPimDictionary),
              });
            }
          });

        products = this.sortNonUsfProducts(products, sortOptions);
        return products;
      },
    );
  };

  public getFakeProductsFromNonUSfProductsBasedOnId = (ids: number[]) => {
    return createSelector(allNonUSFProducts(), productMap => {
      const productsFound = productMap
        .filter(product => ids.includes(product.productNumber))
        .map(prod =>
          transformNonUsfProductToProduct(prod as NonUSFProductViewModel),
        );

      const shortIDList = ids.filter(
        id =>
          productMap.find(product => product.productNumber === id) ===
          undefined,
      );

      const productsNotFound = shortIDList.map(value =>
        transformNonUsfProductToProduct({
          divisionNumber: productsFound[0].summary.divisionNumber ?? 0,
          customerNumber: 0,
          productDescLong: '',
          productNumber: value,
          isSelected: false,
          vendorName: undefined,
        }),
      );

      return [...productsFound, ...productsNotFound];
    });
  };

  public getAllNonUSFProductsAndConvertToFakeProducts = () => {
    return createSelector(
      allNonUSFProducts(),
      allExternalVendors(),
      (productMap, vendors) => {
        const products: NonUSFProductViewModel[] = [];
        productMap.forEach(product => {
          if (!!product) {
            const vendorFound: ExternalVendorState = vendors.find(vendor => {
              return vendor.vendorId === product.vendorId;
            });
            products.push({
              ...product,
              isSelected: false,
              vendorName: vendorFound?.distributorName ?? '',
            });
          }
        });
        return products.map(prod => transformNonUsfProductToProduct(prod));
      },
    );
  };

  public getNonUsfProductAnalyticsData = () => {
    return createSelector(allNonUSFProducts(), nonUsfProducts => {
      let productsForTracking = [];
      nonUsfProducts?.forEach(nonUsfProduct => {
        productsForTracking.push({
          divisionApn:
            nonUsfProduct.divisionNumber + '-' + nonUsfProduct.productNumber,
          nonUsfProductName: nonUsfProduct.productDescLong,
          nonUsfProductPrice: nonUsfProduct.price,
          nonUsfProductView: '1',
        });
      });
      return productsForTracking;
    });
  };
}
