
export const highlightText = (text: string, offset: number, length: number): string => {
  if (offset < 0 || length <= 0 || offset >= text.length) {
    return titleCase(text);
  }
  const start = text.substring(0, offset);
  const highlight = text.substring(offset, offset + length);
  const end = text.substring(offset + length);

  const titleCasedStart = titleCase(start);
  const titleCasedHighlight = titleCase(highlight);
  const titleCasedEnd = titleCase(end);
  return `${titleCasedStart}<strong>${titleCasedHighlight}</strong>${titleCasedEnd}`;
}

export const titleCase = (text: string): string => {
  return text.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}
