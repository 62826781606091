<div
  *ngIf="selectedAction === ListActions.viewSelected"
  class="view-selected-prods-overlay-left"
></div>

<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$ | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="selected-products-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="selected-products-label">
      {{
        (selectedProducts?.length > 1
          ? 'i18n.lists.selectedProducts'
          : 'i18n.lists.selectedProduct'
        ) | translate
      }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="selected-products_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-desktop'
  "
  tabletTouchMove
>
  <ion-grid>
    <ion-row>
      <!-- SELECTED PRODUCT ACTIONS -->
      <ion-col size="5" class="selected-products-actions">
        <ion-item-divider></ion-item-divider>
        <ion-item
          detail
          (click)="changeSelectedProductsAction(ListActions.viewSelected)"
          [class.selected]="selectedAction === ListActions.viewSelected"
          data-cy="click-view-selected-item"
        >
          <ion-label data-cy="view-selected-label">
            {{ 'i18n.lists.viewSelected' | translate }}
            {{
              (selectedProducts?.length > 1
                ? 'i18n.lists.products'
                : 'i18n.lists.product'
              ) | translate
            }}
          </ion-label>
        </ion-item>
        <ion-item
          *ngIf="!fromListDetail"
          detail
          (click)="changeSelectedProductsAction(ListActions.move)"
          [class.selected]="selectedAction === ListActions.move"
          data-cy="click-move-selected-item"
        >
          <ion-label data-cy="move-selected-label">
            {{ 'i18n.lists.move' | translate }}
          </ion-label>
        </ion-item>
        <ion-item
          detail
          (click)="changeSelectedProductsAction(ListActions.delete)"
          [class.selected]="selectedAction === ListActions.delete"
          data-cy="click-delete-selected-item"
        >
          <ion-label data-cy="delete-selected-label">
            {{ 'i18n.common.delete' | translate }}
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- SELECTED PRODUCT INPUT -->
      <ion-col offset="1.5" size="5.5" class="selected-products-input">
        <!-- VIEW SELECTED PRODUCTS -->
        <div
          *ngIf="selectedAction === ListActions.viewSelected"
          class="view-selected-products-container"
        >
          <ion-card
            *ngFor="let product of selectedProducts"
            class="selected-product"
          >
            <div class="selected-products-card-left">
              <ion-icon name="trash-outline"></ion-icon>
            </div>
            <div class="selected-products-card-right">
              <div
                class="product-description"
                data-cy="selected-product-description-text"
              >
                {{
                  product?.product?.productDescTxtl ||
                    product?.product?.productDescriptionLong
                }}
              </div>
              <div
                [attr.data-cy]="
                  'selected-product-' +
                  product?.product?.productNumber +
                  '-number-text'
                "
              >
                ({{ product?.product?.productNumber }})
              </div>
            </div>
          </ion-card>
        </div>
        <!-- MOVE/COPY/DELETE -->
        <div
          *ngIf="selectedAction !== ListActions.viewSelected"
          class="move-copy-delete-container"
        >
          <div class="selected-products-memos">
            <span
              *ngIf="selectedAction === ListActions.move"
              data-cy="select-product-memo-move-text"
            >
              {{ 'i18n.lists.whereToMove' | translate }}
              {{
                (selectedProducts.length > 1
                  ? 'i18n.lists.theseProducts'
                  : 'i18n.lists.thisProduct'
                ) | translate
              }}
            </span>
            <span
              *ngIf="selectedAction === ListActions.copy"
              data-cy="select-product-memo-copy-text"
            >
              {{ 'i18n.lists.whereToCopy' | translate }}
              {{
                (selectedProducts.length > 1
                  ? 'i18n.lists.theseProducts'
                  : 'i18n.lists.thisProduct'
                ) | translate
              }}
            </span>
            <span
              *ngIf="selectedAction === ListActions.delete"
              data-cy="select-product-memo-delete-text"
            >
              {{ 'i18n.lists.whereToDelete' | translate }}
              {{
                (selectedProducts.length > 1
                  ? 'i18n.lists.theseProducts'
                  : 'i18n.lists.thisProduct'
                ) | translate
              }}
            </span>
          </div>
          <div class="groups-list">
            <ion-item-divider></ion-item-divider>
            <ng-container *ngFor="let group of selectedGroups">
              <ion-item
                *ngIf="showGroup(group)"
                (click)="
                  onSelectGroup(group?.groupState?.groupName, group.isSelected)
                "
                data-cy="click-select-group-item"
              >
                <ion-label data-cy="selected-products-group-name-text">{{
                  group?.groupState?.groupName
                }}</ion-label>
                <ion-icon
                  *ngIf="group.isSelected"
                  name="checkmark-circle-outline"
                ></ion-icon>
              </ion-item>
            </ng-container>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  *ngIf="selectedAction !== ListActions.viewSelected"
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-selected-products-modal-button"
        [ngClass]="
          numGroupsSelected < 1
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="dispatchAction()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
