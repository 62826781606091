import { createReducer, on } from '@ngrx/store';
import { UserState } from '../../models/user-state';
import { UserActions } from '../actions/action-types';
import { UserPreferencesState } from '../../models/user-preferences-state';
import { UserProfileState } from '@app/user/models/user-profile-state';
import { UserPreferencesV2State } from '@app/user/models/user-preferences-v2-state';

export const initialUserState: UserState = {
  user: null,
  profilesState: {
    loaded: false,
    loading: false,
  } as UserProfileState,
  userPreferencesState: {
    loaded: false,
    loading: false,
  } as UserPreferencesState,
  userPreferencesV2State: {
    preferences: null,
    loading: false,
    error: null,
  } as UserPreferencesV2State,
  customizations: null,
  emailAndPhone: null,
  loading: false,
  error: null,
  interactBanners: null,
  customMessage: null,
};

export const reducer = createReducer(
  initialUserState,
  on(UserActions.getUserByToken, (state, action) => ({
    ...state,
    user: null,
    profilesState: {
      ...state.profilesState,
      loading: true,
      loaded: false,
      profiles: [],
    },
    loading: true,
    error: null,
  })),

  on(UserActions.getUserByTokenSuccess, (state, action) => ({
    ...state,
    user: action.user,
    profilesState: {
      ...state.profilesState,
      loaded: true,
      loading: false,
      profiles: action.profiles,
    },
    loading: false,
    error: null,
  })),

  on(UserActions.getUserCustomizationSuccess, (state, action) => ({
    ...state,
    customizations: action.customization,
    loading: false,
    error: null,
  })),

  on(UserActions.getUserCustomizationFail, (state, action) => ({
    ...state,
    customizations: null,
    loading: false,
    error: action.error,
  })),

  on(UserActions.getUserByTokenFail, (state, action) => ({
    ...state,
    user: null,
    profilesState: {
      ...state.profilesState,
      loaded: true,
      loading: false,
      error: action.error,
      profiles: [],
    },
    loading: false,
    error: action.error,
  })),

  on(UserActions.getUserPreferences, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: true,
      loaded: false,
      preferences: null,
    },
  })),

  on(UserActions.getUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: false,
      loaded: true,
      preferences: action.preferences.value,
    },
  })),

  on(UserActions.getUserPreferencesFail, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: false,
      loaded: true,
      preferences: null,
      error: action.error,
    },
  })),

  on(UserActions.updateUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: false,
      loaded: true,
    },
  })),

  on(UserActions.updateUserPreferences, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: true,
      loaded: false,
      preferences: action.newPreferences,
      error: undefined,
    },
  })),

  on(UserActions.updateUserPreferencesFail, (state, action) => ({
    ...state,
    userPreferencesState: {
      ...state.userPreferencesState,
      loading: false,
      loaded: true,
      preferences: action.oldPreferences,
      error: action.error,
    },
  })),

  on(UserActions.getEmailPhoneByUsernameSuccess, (state, action) => ({
    ...state,
    emailAndPhone: action.emailAndPhone,
    loading: false,
  })),

  on(UserActions.getInteractBannersSuccess, (state, action) => ({
    ...state,
    interactBanners: action.interactBanners,
    loading: false,
  })),

  on(UserActions.getCustomMessageSuccess, (state, action) => ({
    ...state,
    customMessage: action.customMessage,
    loading: false,
  })),

  on(UserActions.getCustomMessagesSuccess, (state, action) => ({
    ...state,
    customMessages: action.customMessages,
    loading: false,
  })),

  on(UserActions.deleteCustomMessageSuccess, (state, action) => ({
    ...state,
    customMessages: state.customMessages.filter(
      cm => !action.messageIds.includes(cm.messageId),
    ),
    customMessage: {
      ...(state.customMessage.messageId === action.messageIds[0]
        ? null
        : state.customMessage),
    },
    loading: false,
  })),

  on(UserActions.saveCustomMessageSuccess, (state, action) => ({
    ...state,
    customMessage: !state.customMessage
      ? {
          messageId: action.customMessage.messageId,
          link: action.customMessage.link,
          text: action.customMessage.text,
          customerNumber: action.customMessage.customers[0].customerNumber,
          divisionNumber: action.customMessage.customers[0].divisionNumber,
        }
      : state?.customMessage?.messageId === action.customMessage.messageId
        ? {
            messageId: action.customMessage.messageId,
            link: action.customMessage.link,
            text: action.customMessage.text,
            customerNumber: state?.customMessage?.customerNumber,
            divisionNumber: state?.customMessage?.divisionNumber,
          }
        : state.customMessage,
    loading: false,
  })),
  on(UserActions.getUserPreferencesV2, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.getUserPreferencesV2Success, (state, { preferences }) => {
    const existingFavorites =
      state.userPreferencesV2State.preferences?.favoriteCustomers || [];

    const incomingFavorites = preferences.favoriteCustomers || [];

    const uniqueFavorites = [
      ...existingFavorites,
      ...incomingFavorites.filter(
        newFavorite =>
          !existingFavorites.some(
            existingFavorite =>
              existingFavorite.divisionNumber === newFavorite.divisionNumber &&
              existingFavorite.customerNumber === newFavorite.customerNumber,
          ),
      ),
    ];

    return {
      ...state,
      userPreferencesV2State: {
        ...state.userPreferencesV2State,
        preferences: {
          ...preferences,
          favoriteCustomers: uniqueFavorites,
        },
        loading: false,
        error: null,
      },
    };
  }),
  on(UserActions.getUserPreferencesV2Fail, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(UserActions.updateUserPreferencesV2, state => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(UserActions.updateUserPreferencesV2Success, (state, { preferences }) => ({
    ...state,
    userPreferencesV2State: {
      ...state.userPreferencesV2State,
      preferences: {
        ...state.userPreferencesV2State.preferences,
        ...preferences,
      },
      loading: false,
      error: null,
    },
  })),
  on(
    UserActions.updateUserPreferencesV2Fail,
    (state, { error, oldPreferences }) => ({
      ...state,
      userPreferencesV2State: {
        ...state.userPreferencesV2State,
        preferences: oldPreferences,
        loading: false,
        error,
      },
    }),
  ),
);
