import { Customer } from '@usf/customer-types';
import { selectedCustomer } from 'src/app/ngrx-customer/store/selectors/customer.selectors';
import { createSelector } from '@ngrx/store';
import {
  LoadingState,
  productAdapter,
  selectProductDetail,
  selectProductsState,
  selectProductDetails,
} from '@usf/ngrx-product';
import {
  appStateForProductsSelector,
  listDataForProduct,
  productByIdSelector,
  productWithAlternativesSelector,
} from '../../shared/selectors/product.selectors';
import {
  ListItemProduct,
  listItemProductMap,
  masterListItemProductMap,
  selectSellerRecentPurchaseState,
  sellerRecentPurchaseAdapter,
} from '@usf/ngrx-list';

import { Product } from '../../shared/models/product.model';
import { ProductDetailState } from '@usf/ngrx-product/lib/models/product-detail-state.model';
import { extractClaims } from '../utils/product-claims-util';
import {
  ProductDetailViewModel,
  ProductNutritionFacts,
} from '../models/product-detail-view-model';
import { extractPoDates } from '../utils/po-dates-util';
import { productNonFoodClassDescriptions } from '../utils/product-summary.utils';

import { findMostRecentWeeklyMovement } from '../utils/weekly-movement.util';
import { ProductPriceUtil } from '../utils/product-price.util';
import { IPromotionState, selectPromotionState } from '@usf/ngrx-promotions';
import { Dictionary } from '@ngrx/entity';
import { selectAllFeaturesEnabled, selectSession } from '@panamax/app-state';
import { FEATURES } from '../../shared/constants/splitio-features';
import { MListItemProduct } from '@usf/ngrx-list/lib/reducers/master-list-item/master-list-item.reducer';
import { OGRestrictionsEnum } from '../../shared/constants/sub-restrictions-enum';
import * as nutritionalInfoUtil from '@product-detail/utils/nutritional-info.util';
import { checkElementWasFound } from '@shared/selectors/helpers/product-info.selectors.helper';
import { getDisplayTags } from '../utils/product-tags-util';
import { ListDataForProduct } from '../../shared/models/list-data-for-product';
import { AppStateForProduct } from '../../shared/models/app-state-for-product';

// TODO REMOVE THIS
export const selectProductById = (productId: string) =>
  createSelector(
    productAdapter.getSelectors(selectProductsState).selectEntities,
    lists => lists[productId],
  );

export const productDetailViewModelSelector = productNumber =>
  createSelector(
    productByIdSelector(productNumber),
    selectProductDetail(productNumber),
    sellerRecentPurchaseAdapter.getSelectors(selectSellerRecentPurchaseState)
      .selectEntities,
    selectedCustomer,
    selectPromotionState,
    listItemProductMap(),
    selectAllFeaturesEnabled([FEATURES.split_global_master_lists]),
    masterListItemProductMap(),
    selectSession(),
    (
      product: Product,
      productDetail: ProductDetailState,
      recentPurchaseByCustomers: Record<number, any>,
      selectedCustomer: Customer,
      promotionState: IPromotionState,
      listItemProductMap: Dictionary<ListItemProduct>,
      masterListFlag: boolean,
      masterListItemProductMap: Dictionary<MListItemProduct>,
      currentSession,
    ) => {
      // Check data is there
      if (
        product === undefined ||
        productDetail === undefined ||
        recentPurchaseByCustomers === undefined
      ) {
        return undefined;
      }

      // Update product properties
      if (!!product?.summary) {
        product.summary.poDates = extractPoDates(product.summary.poDates);

        // Merge in additional properties
        product.summary.properties = new Set([
          ...product.summary.properties,
          ...product.summary.additionalProperties,
        ]);
      }

      // Update product claims & PO dates
      productDetail = {
        ...productDetail,
        claims: extractClaims(productDetail?.claims),
      };
      const otherCustomerPurchases =
        recentPurchaseByCustomers[product.productNumber]?.purchases?.filter(
          value => value.customerNumber !== selectedCustomer.customerNumber,
        ) ?? [];

      // cost breakdown and case contents
      const caseContents = ProductPriceUtil.getCaseContents(
        productDetail?.standardComparisonUOM,
        productDetail?.standardComparisonValue,
        product?.summary,
      );

      const costBreakdown = ProductPriceUtil.calculateCostBreakdown(
        product?.summary,
        selectedCustomer,
        product?.pricing,
        productDetail?.standardComparisonUOM,
        productDetail?.standardComparisonValue,
      );

      // product tags
      const productTags = getDisplayTags(
        masterListFlag,
        product?.summary,
        product?.contract,
        product?.customerPill,
        product?.recentPurchase,
        currentSession.isGuest,
      );

      const viewModel: ProductDetailViewModel = {
        ...product,
        detail: productDetail,
        recentPurchase: product.recentPurchase,
        recentPurchaseByCustomers: otherCustomerPurchases,
        isNonFood: productNonFoodClassDescriptions.has(
          product?.summary.classDescription,
        ),
        currentWeeklyMovement: findMostRecentWeeklyMovement(
          productDetail.salesMovementChanges,
        ),
        selectedCustomer,
        caseContents,
        costBreakdown,
        allowSearchForSubs: product.allowSearchForSubs,
        productTags,
        notFound:
          productDetail?.loadingState === LoadingState.notFound ||
          productDetail?.loadingState === LoadingState.error,
      };
      viewModel.promotionDetails =
        promotionState.entities[viewModel.productNumber];

      if (listItemProductMap) {
        const listItemProduct = listItemProductMap[viewModel.productNumber];
        if (listItemProduct) {
          viewModel.lists = listItemProduct.lists;
          viewModel.customerProductNumber =
            listItemProduct.customerProductNumber;
        }
      }
      if (masterListFlag) {
        if (masterListItemProductMap) {
          const masterListItemProduct =
            masterListItemProductMap[viewModel.productNumber];
          if (masterListItemProduct) {
            viewModel.masterLists = masterListItemProduct.masterLists ?? [];
            viewModel.isMslRestricted =
              selectedCustomer.restrictToOG ===
              OGRestrictionsEnum.RESTRICT_TO_ML;
          }
        }
      }
      return viewModel;
    },
  );

export const productDetailsViewModelSelector = (productNumbers: number[]) =>
  createSelector(
    productWithAlternativesSelector,
    selectProductDetails(productNumbers),
    sellerRecentPurchaseAdapter.getSelectors(selectSellerRecentPurchaseState)
      .selectEntities,
    selectedCustomer,
    selectPromotionState,
    listDataForProduct,
    appStateForProductsSelector,
    (
      productMap: Map<number, Product>,
      productDetails: ProductDetailState[],
      recentPurchaseByCustomers: Record<number, any>,
      selectedCustomer: Customer,
      promotionState: IPromotionState,
      listDataForProduct: ListDataForProduct,
      appStateForProduct: AppStateForProduct,
    ) => {
      // convert selected product details to map and check that they are loaded
      const productDetailsMap = productDetails.reduce((map, productDetail) => {
        if (!!productDetail) {
          return map.set(productDetail.productNumber, productDetail);
        } else {
          return map;
        }
      }, new Map<number, ProductDetailState>());

      return productNumbers.reduce((map, productNumber) => {
        let product = productMap.get(productNumber);

        // Check data is there
        if (product === undefined || recentPurchaseByCustomers === undefined) {
          return map.set(productNumber, null);
        }

        // Update product properties
        if (!!product?.summary) {
          product.summary.poDates = extractPoDates(product.summary.poDates);

          // Merge in additional properties
          product.summary.properties = new Set([
            ...product.summary.properties,
            ...product.summary.additionalProperties,
          ]);
        }

        // Update product claims & PO dates
        if (!productDetailsMap.has(productNumber)) {
          return map.set(productNumber, null);
        }
        const productDetail = {
          ...productDetailsMap.get(productNumber),
          claims: extractClaims(productDetailsMap.get(productNumber)?.claims),
        };
        const otherCustomerPurchases =
          recentPurchaseByCustomers[product.productNumber]?.purchases?.filter(
            value => value.customerNumber !== selectedCustomer.customerNumber,
          ) ?? [];

        // cost breakdown and case contents
        const caseContents = ProductPriceUtil.getCaseContents(
          productDetail?.standardComparisonUOM,
          productDetail?.standardComparisonValue,
          product?.summary,
        );

        const costBreakdown = ProductPriceUtil.calculateCostBreakdown(
          product?.summary,
          selectedCustomer,
          product?.pricing,
          productDetail?.standardComparisonUOM,
          productDetail?.standardComparisonValue,
        );

        let nutritionFacts: ProductNutritionFacts = {
          supplementalFacts: [],
          calories: 0,
          servingSizeData: '',
          nutrientsData: [],
          nutrientIsNotAvailable: false,
        };

        let nutrients = productDetail?.listPIMNutrientsProduction;
        nutrients = nutritionalInfoUtil.filterNutrientsByPriority(nutrients);
        nutritionFacts.supplementalFacts =
          nutritionalInfoUtil.extractSupplementalFacts(nutrients);
        nutritionFacts.calories =
          nutritionalInfoUtil.extractCalories(nutrients);
        nutritionFacts.servingSizeData =
          nutritionalInfoUtil.extractServingSize(nutrients);
        nutritionFacts.nutrientsData =
          nutritionalInfoUtil.extractNutrients(nutrients);
        nutritionFacts.nutrientIsNotAvailable =
          !nutritionalInfoUtil.isNutrientDataAvailable(nutrients);

        // product tags
        const productTags = getDisplayTags(
          product?.summary?.mslUnrestrict
            ? false
            : appStateForProduct.isMslRestricted,
          product?.summary,
          product?.contract,
          product?.customerPill,
          product?.recentPurchase,
        );

        const viewModel: ProductDetailViewModel = {
          ...product,
          detail: productDetail,
          recentPurchase: product.recentPurchase,
          recentPurchaseByCustomers: otherCustomerPurchases,
          isNonFood: productNonFoodClassDescriptions.has(
            product?.summary.classDescription,
          ),
          currentWeeklyMovement: findMostRecentWeeklyMovement(
            productDetail.salesMovementChanges,
          ),
          selectedCustomer,
          caseContents,
          costBreakdown,
          allowSearchForSubs: product.allowSearchForSubs,
          nutritionFacts: nutritionFacts,
          productTags,
          notFound:
            productDetail?.loadingState === LoadingState.notFound ||
            productDetail?.loadingState === LoadingState.error,
        };
        viewModel.promotionDetails =
          promotionState.entities[viewModel.productNumber];

        if (listDataForProduct.listItemProductMap) {
          const listItemProduct =
            listDataForProduct.listItemProductMap[viewModel.productNumber];
          if (listItemProduct) {
            viewModel.lists = listItemProduct.lists;
            viewModel.customerProductNumber =
              listItemProduct.customerProductNumber;
          }
        }
        if (appStateForProduct.masterListFeatureFlag) {
          if (listDataForProduct.masterListItemProductMap) {
            const masterListItemProduct =
              listDataForProduct.masterListItemProductMap[
                viewModel.productNumber
              ];
            if (masterListItemProduct) {
              viewModel.masterLists = masterListItemProduct.masterLists ?? [];
              viewModel.isMslRestricted =
                selectedCustomer.restrictToOG ===
                OGRestrictionsEnum.RESTRICT_TO_ML;
            }
          }
        }
        return map.set(productNumber, viewModel);
      }, new Map<number, ProductDetailViewModel>());
    },
  );
