import { Injectable } from '@angular/core';
import { CustomMessageBannerInfo } from '@app/home/models/custom-message-banner-info.model';
import { CustomMessage } from '@usf/user-types/custom-message';
import { User, UpdateContactInfoDto } from '@usf/user-types/user';
import { Preferences, UserPreferences } from '@usf/user-types/user-preference';
import { UserProfile } from '@usf/user-types/user-profile';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';
import { ServiceHandlerService } from 'src/app/shared/services/service-handler.service';
import { environment } from 'src/environments/environment';
import { InteractBannerInfo } from '../../home/models/interact-banner-info.model';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { InvitationTokenResponse } from '../models/invitation-token-response';
import { HttpHeaders } from '@angular/common/http';
import { CCAInvitationTokenResponse } from '../models/cca-invitation-token-response';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  constructor(private serviceHandler: ServiceHandlerService) {}

  getByToken(): Observable<any> {
    return this.serviceHandler.get(`${environment.userApiUrl}/identity`).pipe(
      take(1),
      filter(res => !!res),
      catchError(error => throwError(error)),
    );
  }

  getUserPreferences(): Observable<UserPreferences> {
    return this.serviceHandler
      .get<UserPreferences>(`${environment.userApiUrl}/userpreferences`)
      .pipe(
        map(res => (!!res ? res : ({} as UserPreferences))),
        catchError(error => throwError(error)),
      );
  }

  updateUserPreferences(body: Preferences): Observable<UserPreferences> {
    return this.serviceHandler
      .put(`${environment.userApiUrl}/userpreferences`, body)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as UserPreferences),
      );
  }

  getUserProfiles(): Observable<UserProfile[]> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/userprofiles`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as UserProfile[]),
      );
  }

  getUserCustomizations(): Observable<SiteCustomizationProfile> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/sitecustomizations`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as SiteCustomizationProfile),
      );
  }

  getEmailPhoneByUsername(username: string): Observable<any> {
    return this.serviceHandler
      .get(
        `${environment.userApiUrl}/users/getEmailPhoneByUsername?username=${username}`,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  updateContactInformation(contactInfo: UpdateContactInfoDto): Observable<any> {
    return this.serviceHandler
      .put(
        `${environment.userApiUrl}/users/updateContactInformation`,
        contactInfo,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
      );
  }

  getInteractBanners(): Observable<InteractBannerInfo[]> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/custom-messages/getInteractBanner`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  getCustomMessage(): Observable<CustomMessageBannerInfo> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/custom-messages/getCustomMessage`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  getCustomMessages(): Observable<CustomMessage[]> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/custom-messages/customMessages`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  saveCustomMessage(customMessage: CustomMessage): Observable<any> {
    return this.serviceHandler
      .put(
        `${environment.userApiUrl}/custom-messages/customMessage`,
        customMessage,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  deleteCustomMessage(messageIds: string[]): Observable<any> {
    return this.serviceHandler
      .delete(`${environment.userApiUrl}/custom-messages`, messageIds)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  inviteNewUser(
    inviteEmail: string,
    customers?: any[],
    fullUserProfile?: any,
    organizationName?: string,
  ): Observable<any> {
    return this.serviceHandler
      .post(`${environment.userApiUrl}/inviteUser`, {
        inviteEmail: inviteEmail,
        customers: customers,
        fullUserProfile: fullUserProfile,
        organizationName: organizationName,
      })
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  getInvitationToken(shortCode: string): Observable<InvitationTokenResponse> {
    const credentials =
      environment.basicAuthUsername + ':' + environment.basicAuthPassword;
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa(credentials),
      }),
    };

    return this.serviceHandler
      .get(
        `${environment.userApiUrl}/userinvitations/${shortCode}/token`,
        null,
        requestOptions.headers,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(() => new Error(error))),
        map(res => res as any),
      );
  }

  getCCAInvitationToken(
    shortCode: string,
  ): Observable<CCAInvitationTokenResponse> {
    const credentials =
      environment.basicAuthUsername + ':' + environment.basicAuthPassword;
    const requestOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + btoa(credentials),
      }),
    };

    return this.serviceHandler
      .get(
        `${environment.userApiUrl}/ccauserinvitation/${shortCode}/token`,
        null,
        requestOptions.headers,
      )
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(() => new Error(error))),
        map(res => res as any),
      );
  }

  searchUserByUsername(userName: string): Observable<User> {
    return this.serviceHandler
      .post(`${environment.userApiUrl}/userSearch`, {
        userName: userName,
      })
      .pipe(
        take(1),
        map(res => ((res as any[]).length > 0 ? res[0] : { userName: '' })),
        catchError(error => throwError(error)),
      );
  }

  getAllSiteCustomizationProfiles(): Observable<SiteCustomizationProfile[]> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/sitecustomizations/profiles`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as SiteCustomizationProfile[]),
      );
  }

  saveUserReview(): Observable<any> {
    return this.serviceHandler
      .post(`${environment.userApiUrl}/user-reviews/saveUserReview`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }

  canRequestReview(): Observable<any> {
    return this.serviceHandler
      .get(`${environment.userApiUrl}/user-reviews/canReview`)
      .pipe(
        take(1),
        filter(res => !!res),
        catchError(error => throwError(error)),
        map(res => res as any),
      );
  }
}
