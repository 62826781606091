import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserActions } from '@app/user/store/actions/action-types';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AssignedCustomerState,
  PanAppState,
  USER_ACTIONS,
  CUSTOMER_ACTIONS as AppStateCustomerActions,
} from '@panamax/app-state';
import { FEATURES } from '@shared/constants/splitio-features';
import { Customer } from '@usf/customer-types/customer';
import { ALERTS_ACTIONS, NOTES_ACTIONS, TRACE_CONTEXT } from '@usf/ngrx-alerts';
import { of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CustomerService } from '../../../customer/services/customer.service';
import { CustomerDataService } from '../../services/customer/customer-data.service';
import * as CustomerActions from '../actions/customer.actions';
import {
  CUSTOMER_ACTIONS,
  CUSTOMER_SWITCH_ACTIONS,
} from '../actions/customer.actions';
import { UserService } from '@app/user/services';
import { CustomerSwitchStatus } from '@app/ngrx-customer/constants/customer-switch-status';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { notificationsTransformerFunc } from '@notifications/tracing/notifications.transformers';

@Injectable({
  providedIn: 'root',
})
export class CustomerEffects {
  customerList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.getCustomerList),
      exhaustMap(() =>
        this.userService.isSuperUser$().pipe(
          withLatestFrom(this.panamax.session$),
          switchMap(([isSuperUser, sessionState]) => {
            return isSuperUser
              ? this.customerDataService.get<Customer>(
                  'customers',
                  sessionState.selectedCustomerId,
                  sessionState.division,
                )
              : this.customerDataService.get<Customer>('customers');
          }),
          withLatestFrom(
            this.panamax.session$,
            this.panamax.feature$([FEATURES.split_global_interact_banners]),
            this.panamax.feature$([
              FEATURES.split_global_alerts_custom_message,
            ]),
            this.userService.isGuestUser$(),
          ),
          filter(([customers, session]) => !!customers && !!session),
          concatMap(
            ([
              customers,
              session,
              areInteractBannersEnabled,
              areCustomMessagesEnabled,
              isGuestUser,
            ]) => {
              const assignedCustomers: AssignedCustomerState[] = customers.map(
                customer => ({
                  customerNumber: customer.customerNumber,
                  divisionNumber: customer.divisionNumber,
                  departmentNumbers: customer.departmentNumbers,
                }),
              );

              const returnActions = [
                CustomerActions.getCustomerListSuccess({
                  customers: this.setSelectedCustomer(
                    customers,
                    session.selectedCustomerId,
                    session.department,
                  ),
                }),
                USER_ACTIONS.loadAssignedCustomers({ assignedCustomers }),
              ];

              const alertsActions = [];

              if (!isGuestUser) {
                alertsActions.push(
                  ALERTS_ACTIONS.getUnreadAlertsCount({
                    tracking: {
                      tracing: {
                        data: {
                          traceContext: TRACE_CONTEXT.getUnreadAlertsCount,
                          isStartOfTrace: true,
                          isEndOfTrace: false,
                        },
                        transformFunc: notificationsTransformerFunc,
                      },
                    },
                  }),
                  NOTES_ACTIONS.getNotes({
                    tracking: {
                      tracing: {
                        data: {
                          traceContext: TRACE_CONTEXT.getNotes,
                          isStartOfTrace: true,
                          isEndOfTrace: false,
                        },
                        transformFunc: notificationsTransformerFunc,
                      },
                    },
                  }),
                );
              }
              if (areCustomMessagesEnabled) {
                alertsActions.push(UserActions.getCustomMessage());
              }
              if (areInteractBannersEnabled) {
                alertsActions.push(UserActions.getInteractBanners());
              }
              return [...returnActions, ...alertsActions];
            },
          ),
          catchError(error =>
            of(CustomerActions.getCustomerListFail({ error })),
          ),
        ),
      ),
    ),
  );

  retrieveCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CUSTOMER_ACTIONS.retrieveCustomer),
      concatMap(action =>
        this.customerDataService
          .retrieveCustomer(action.customerNumber, action.divisionNumber)
          .pipe(
            map(customer =>
              CUSTOMER_ACTIONS.retrieveCustomerSuccess({
                customer,
              }),
            ),
            catchError((error: HttpErrorResponse) =>
              of(
                CUSTOMER_ACTIONS.retrieveCustomerFail({
                  error,
                }),
              ),
            ),
          ),
      ),
    ),
  );
  retrieveCustomerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CUSTOMER_ACTIONS.retrieveCustomerSuccess),
        map(action => {
          this.customerService.setRetrievedCustomer(action.customer);
        }),
      ),
    {
      dispatch: false,
    },
  );
  searchCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CUSTOMER_ACTIONS.searchCustomers),
      concatMap(action =>
        this.customerDataService.searchCustomers(action.searchKey).pipe(
          map(customerSummaries =>
            CUSTOMER_ACTIONS.searchCustomersSuccess({
              customerSummaries,
            }),
          ),
          catchError((error: HttpErrorResponse) =>
            of(
              CUSTOMER_ACTIONS.searchCustomersFail({
                error,
              }),
            ),
          ),
        ),
      ),
    ),
  );

  searchCustomersSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CUSTOMER_ACTIONS.searchCustomersSuccess),
        map(action => {
          this.customerService.setShowCustomersDataLoadingSpinner(false);
          this.customerService.setFilteredCustomerSummaries(
            action.customerSummaries ?? [],
          );
        }),
      ),
    {
      dispatch: false,
    },
  );

  searchCustomersFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CUSTOMER_ACTIONS.searchCustomersFail),
        tap(() => {
          this.customerService.setShowCustomersDataLoadingSpinner(false);
        }),
      ),
    {
      dispatch: false,
    },
  );

  selectedCustomerLoaded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppStateCustomerActions.loadSelectedCustomerSuccess),
      map(action =>
        CUSTOMER_SWITCH_ACTIONS.setCustomerSwitchStatus({
          customerSwitchStatus: CustomerSwitchStatus.complete,
        }),
      ),
    ),
  );

  getActiveCustomersByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.getActiveCustomersByUserId),
      concatMap(action =>
        this.customerDataService.getActiveCustomersByUserId(action.userId).pipe(
          withLatestFrom(this.customerStoreService.loadCustomers$()),
          map(([response, customers]) => {
            const userCustomer = response;
            const enrichedUserCustomers = customers.map(customer => {
              const isSelected = userCustomer.customerIds.some(
                customerKey =>
                  customer.customerNumber === customerKey.customerNumber &&
                  customer.divisionNumber === customerKey.divisionNumber,
              );

              return {
                ...customer,
                selected: isSelected,
              };
            });

            return CustomerActions.getActiveCustomersByUserIdSuccess({
              userCustomer: {
                ...userCustomer,
                customerIds: enrichedUserCustomers,
              },
            });
          }),
          catchError((error: HttpErrorResponse) =>
            of(CustomerActions.getActiveCustomersByUserIdFail({ error })),
          ),
        ),
      ),
    ),
  );

  updateCustomersByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActions.updateCustomersByUserId),
      concatMap(action =>
        this.customerDataService
          .updateCustomersByUserId(action.userId, action.customerIds)
          .pipe(
            map((response: HttpResponse<any>) => {
              if (!response || typeof response === 'string') {
                return CustomerActions.updateCustomersByUserIdSuccess();
              }
              return CustomerActions.updateCustomersByUserIdSuccess();
            }),
            catchError((error: any) =>
              of(CustomerActions.updateCustomersByUserIdFail({ error })),
            ),
          ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private customerService: CustomerService,
    private customerDataService: CustomerDataService<any>,
    private customerStoreService: CustomerStoreService,
    private panamax: PanAppState,
    private userService: UserService,
  ) {}

  private setSelectedCustomer(
    customers: any,
    customerNumber: number,
    departmentNumber: number,
  ): Customer[] {
    for (const customer of customers) {
      if (customer.customerNumber === customerNumber) {
        customer.selected = true;
        customer.departmentNumber = departmentNumber?.toString();
        this.panamax.persistCustomer(customer);
      }
    }
    return customers;
  }
}
