<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="(platformService?.isTouchExperience$ | async) === false"
        id="close-modal-btn"
        data-cy="close-add-to-list-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon
          slot="icon-only"
          name="close-outline"
          data-cy="add-to-list-close-icon"
        ></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="add-to-list-text">
      {{ 'i18n.lists.addToList' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="add-to-list_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-desktop'
  "
  tabletTouchMove
>
  <ion-grid>
    <ion-row>
      <!-- ADD TO LIST OPTIONS -->
      <ion-col size="5" class="add-to-list-options">
        <div class="add-to-list-options-header">
          <ion-label data-cy="add-to-list-info-label">
            {{ 'i18n.lists.addToListInfo' | translate }}
          </ion-label>
        </div>
        <ion-item-divider></ion-item-divider>
        <!-- Choose List -->
        <ion-item
          [class.selected]="selectedAddToListOption === addToList.chooseList"
          detail
          (click)="changeSelectedAddToListOption(addToList.chooseList)"
          data-cy="click-change-selected-list-item"
        >
          <ion-label data-cy="choose-list-label">
            {{ 'i18n.lists.chooseList' | translate }}
            <span data-cy="selected-list-name-text">
              {{ lastSelectedList?.listName ? lastSelectedList?.listName : '' }}
            </span>
          </ion-label>
        </ion-item>
        <!-- Choose Group -->
        <ion-item
          [class.selected]="selectedAddToListOption === addToList.chooseGroup"
          detail
          (click)="changeSelectedAddToListOption(addToList.chooseGroup)"
          data-cy="click-change-selected-group-item"
        >
          <ion-label data-cy="choose-group-label">
            {{ 'i18n.lists.chooseGroup' | translate }}
            <span data-cy="choose-group-text">
              {{ group?.groupName ? group?.groupName : '' }}
            </span>
          </ion-label>
        </ion-item>
        <!-- Choose Position -->
        <ion-item
          [class.selected]="
            selectedAddToListOption === addToList.choosePosition
          "
          detail
          (click)="changeSelectedAddToListOption(addToList.choosePosition)"
          data-cy="click-change-selected-position-item"
        >
          <ion-label data-cy="choose-position-label">
            {{ 'i18n.lists.choosePosition' | translate }}
            <span data-cy="choose-position-text">
              {{
                (groupPosition === addToList.top
                  ? 'i18n.lists.topOfTheGroup'
                  : 'i18n.lists.bottomOfTheGroup'
                ) | translate
              }}
            </span>
          </ion-label>
        </ion-item>
      </ion-col>

      <!-- ADD TO LIST INPUT -->
      <ion-col offset="1.5" size="5.5" class="add-to-list-input">
        <!-- Choose List Options -->
        <div *ngIf="selectedAddToListOption === addToList.chooseList">
          <ion-searchbar
            id="list-searchbar"
            data-cy="list-searchbar"
            class="search"
            mode="md"
            placeholder="{{ 'i18n.lists.searchList' | translate }}"
            [(ngModel)]="searchKey"
            (ionClear)="resetSearchList()"
            (ionInput)="searchList()"
          >
          </ion-searchbar>
          <div *ngIf="filteredLists" class="add-to-list-input-option">
            <ion-radio-group
              [(ngModel)]="lastSelectedList"
              (ionChange)="onChooseList($event)"
            >
              <ion-item-divider
                *ngIf="filteredLists?.length > 0"
              ></ion-item-divider>
              <ion-item *ngFor="let list of filteredLists">
                <ion-radio
                  mode="md"
                  [value]="list"
                  [attr.data-cy]="'list-name' + list?.listName + 'radio'"
                  labelPlacement="start"
                  justify="space-between"
                  >{{ list?.listName }}</ion-radio
                >
              </ion-item>
              <div
                *ngIf="filteredLists?.length === 0 || !filteredLists"
                class="add-to-list-not-found"
              >
                <span data-cy="no-lists-show-text">
                  {{ 'i18n.lists.noListsFound' | translate }}
                </span>
              </div>
            </ion-radio-group>
          </div>
          <div *ngIf="!filteredLists" class="add-to-list-input-option">
            <div class="add-to-list-not-found">
              <span data-cy="no-lists-show-text">
                {{ 'i18n.lists.noListsFound' | translate }}
              </span>
            </div>
          </div>
        </div>
        <!-- Choose Group Options -->
        <div *ngIf="selectedAddToListOption === addToList.chooseGroup">
          <ion-searchbar
            id="list-searchbar"
            data-cy="list-searchbar"
            class="search"
            mode="md"
            placeholder="{{ 'i18n.lists.searchGroup' | translate }}"
            [(ngModel)]="searchKey"
            (ionClear)="resetSearchGroup()"
            (ionInput)="searchGroup()"
          >
          </ion-searchbar>
          <div *ngIf="filteredGroups" class="add-to-list-input-option">
            <ion-radio-group
              [(ngModel)]="group"
              (ionChange)="onChooseGroup($event)"
            >
              <ion-item-divider
                *ngIf="filteredGroups?.length > 0"
              ></ion-item-divider>
              <ion-item *ngFor="let group of filteredGroups">
                <ion-radio
                  mode="md"
                  [value]="group"
                  [attr.data-cy]="'group-name' + group?.groupName + 'radio'"
                  labelPlacement="start"
                  justify="space-between"
                  >{{ group?.groupName }}</ion-radio
                >
              </ion-item>
              <div
                *ngIf="filteredGroups?.length === 0 || !filteredGroups"
                class="add-to-list-not-found"
              >
                <span data-cy="no-groups-show-text">
                  {{ 'i18n.lists.noGroupsFound' | translate }}
                </span>
              </div>
            </ion-radio-group>
          </div>
          <div *ngIf="!filteredGroups" class="add-to-list-input-option">
            <div class="add-to-list-not-found">
              <span data-cy="no-groups-show-text">
                {{ 'i18n.lists.noGroupsFound' | translate }}
              </span>
            </div>
          </div>
        </div>
        <!-- Choose Position Options -->
        <div *ngIf="selectedAddToListOption === addToList.choosePosition">
          <div class="add-to-list-input-option">
            <ion-radio-group
              [(ngModel)]="groupPosition"
              (ionChange)="onChoosePosition($event)"
            >
              <ion-item-divider></ion-item-divider>
              <ion-item>
                <ion-radio
                  mode="md"
                  value="TOP"
                  data-cy="top-of-group-radio"
                  labelPlacement="start"
                  justify="space-between"
                  >{{ 'i18n.lists.topOfTheGroup' | translate }}</ion-radio
                >
              </ion-item>
              <ion-item>
                <ion-radio
                  mode="md"
                  value="BOTTOM"
                  data-cy="bottom-of-group-radio"
                  labelPlacement="start"
                  justify="space-between"
                  >{{ 'i18n.lists.bottomOfTheGroup' | translate }}</ion-radio
                >
              </ion-item>
            </ion-radio-group>
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        data-cy="add-to-list-cancel-button"
        class="usf-outline-green-dsktp-tblt-modal-button"
        (click)="dismissModal()"
      >
        {{ 'i18n.common.cancel' | translate }}
      </ion-button>
      <ion-button
        id="submit-modal-btn"
        data-cy="submit-add-to-list-modal"
        [ngClass]="
          !isValid()
            ? 'usf-fill-disabled-dsktp-tblt-modal-button'
            : 'usf-fill-green-dsktp-tblt-modal-button'
        "
        (click)="onSubmit()"
      >
        {{ 'i18n.common.submit' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
