<ng-container
  *ngIf="right?.searchPane?.searchableObservable$ | async as searchableList"
>
  <div
    class="multiselect-container"
    [class.bold-alternate]="!!right?.countPane?.alternateBolding"
  >
    <ion-label
      [attr.data-cy]="dataCyTag + '-seaarch-title'"
      *ngIf="!!right?.searchPane?.searchTitle"
      class="subtitle search-title"
    >
      {{ right?.searchPane?.searchTitle | translate }}
    </ion-label>
    <ion-searchbar
      *ngIf="!right?.searchPane?.hideSearch"
      [attr.data-cy]="dataCyTag + '-radio-searchbar'"
      class="search"
      mode="md"
      placeholder="{{ right?.searchPane?.searchPlaceholder | translate }}"
      [(ngModel)]="right.searchPane.searchKey"
      (ionClear)="resetSearch()"
      (ionInput)="handleSearch()"
    >
    </ion-searchbar>
    <div class="no-results-found" *ngIf="searchableList?.length === 0">
      {{
        right?.searchPane?.noResultsOverride ??
          'i18n.lists.copyListModal.noResultsFound' | translate
      }}
    </div>
    <div class="select-all-container" *ngIf="!right?.countPane?.hideSelectAll">
      <ion-item
        *ngIf="searchableList?.length > 0"
        class="checkbox-item select-all"
      >
        <ion-checkbox
          justify="end"
          [checked]="
            right?.countPane?.secondaryCount === right?.countPane?.primaryCount
          "
          (ionChange)="handleSelectAllChange($event, searchableList)"
          mode="ios"
        >
          {{
            right?.countPane?.primaryCount === right?.countPane?.secondaryCount
              ? ('i18n.lists.copyListModal.deselectAll' | translate)
              : ('i18n.lists.copyListModal.selectAll' | translate)
          }}
        </ion-checkbox>
      </ion-item>
    </div>
    <cdk-virtual-scroll-viewport
      class="item-container"
      [class.mobile]="platform === platformRef.mobile"
      id="virtual-scroll-{{ dataCyTag }}"
      data-cy="virtual-scroll-{{ dataCyTag }}"
      orientation="vertical"
      [itemHeights]="right?.searchPane?.itemHeights"
      [indexOffset]="0"
      appCustomVs
      (touchmove)="virtualscrolled($event)"
    >
      <ion-item-divider
        *ngIf="
          searchableList?.length > 0 &&
          right?.searchPane?.hideSearch &&
          right?.countPane?.hideSelectAll
        "
        class="clamp-height"
      ></ion-item-divider>
      <ion-item
        *cdkVirtualFor="
          let item of searchableList;
          let i = index;
          trackBy: trackItem
        "
        class="checkbox-item"
        [class.no-move]="item?.type === MultiSelectItemsRef.selectableHeader"
      >
        <div
          *ngIf="
            item?.type === MultiSelectItemsRef.iconBasic ||
            item?.type === MultiSelectItemsRef.iconHeader
          "
          class="circle-badge"
        >
          {{ findFirstLetter(item?.label) }}
        </div>
        <ion-checkbox
          *ngIf="
            item?.type !== MultiSelectItemsRef.iconHeader &&
            item?.type !== MultiSelectItemsRef.selectableHeader
          "
          value="{{ item?.label }}"
          [checked]="item?.selected"
          (ionChange)="handleCheckboxSelection($event, item, searchableList)"
          mode="ios"
          justify="space-between"
          labelPlacement="start"
          class="checkbox"
          [attr.data-cy]="dataCyTag + '-radio-checkbox-' + i"
        >
          <div
            [class.item-subtitle-bold]="!!right?.countPane?.alternateBolding"
            [class.label]="!right?.countPane?.alternateBolding"
          >
            {{ item?.label | translate | titlecase }}
          </div>
        </ion-checkbox>
        <ion-checkbox
          *ngIf="item?.type === MultiSelectItemsRef.selectableHeader"
          [checked]="item?.selected"
          (ionChange)="handleSelectGroup($event, item, searchableList)"
          mode="ios"
          justify="space-between"
          class="title-check"
          [attr.data-cy]="dataCyTag + '-title-checkbox-' + i"
        >
          <div class="selectable-container">
            <div class="label">
              {{ item?.label | titlecase }}
            </div>
            <div class="green">
              {{
                item?.selected
                  ? ('i18n.lists.copyListModal.deselectAll' | translate)
                  : ('i18n.lists.copyListModal.selectAll' | translate)
              }}
            </div>
          </div>
        </ion-checkbox>
        <div
          class="checkbox"
          *ngIf="item?.type === MultiSelectItemsRef.iconHeader"
        >
          {{ item?.label | translate | titlecase }}
        </div>
      </ion-item>
    </cdk-virtual-scroll-viewport>
  </div>
</ng-container>
