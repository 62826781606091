import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { SelectedCustomerState } from '@panamax/app-state';
import {
  Order,
  orderSelectors,
  OrderStatus,
  partnerSelectors,
} from '@usf/ngrx-order';
import { OrderState } from '@usf/ngrx-order';
import {
  productPricingAdapter,
  selectProductPricingState,
} from '@usf/ngrx-pricing';
import { ProductPricing } from '@usf/price-types';
import {
  getCustomers,
  selectAppStateSelectedCustomer,
} from 'src/app/ngrx-customer/store';
import { OrderStatusDetails } from 'src/app/shared/constants/order-status-title.enum';
import { Product } from 'src/app/shared/models/product.model';
import { productWithAlternativesSelector } from 'src/app/shared/selectors/product.selectors';
import {
  MasterOrderForView,
  OrderConfirmationVM,
  SplittedOrder,
} from '../models/order-confirmation-view-model';
import { ProductPropertiesEnum } from '@usf/product-types/Product-Summary';
import {
  SubbedItem,
  SubmittedOrderTallies,
} from '@order/models/submitted-order-view-model';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { Customer } from '@usf/customer-types';
import { OrderService } from '@order/services/order.service';

export const selectOrderConfirmationViewModel = (
  masterOrderId: string,
  autoSubFeatureFlag: boolean,
) =>
  createSelector(
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    productWithAlternativesSelector,
    productPricingAdapter.getSelectors(selectProductPricingState)
      .selectEntities,
    partnerSelectors.selectPartner,
    getCustomers,
    (
      selectedCustomer: SelectedCustomerState,
      allOrders: OrderState,
      productsMap: Map<number, Product>,
      pricingState: Dictionary<ProductPricing>,
      partnerState: PartnerState,
      customers: Customer[],
    ): OrderConfirmationVM => {
      if (allOrders) {
        const masterOrderById: Order = allOrders.entities[masterOrderId]; //This is a Parent/Originally submitted order
        let pricing;
        const orderTallies: SubmittedOrderTallies = {
          totalCases: 0,
          totalEaches: 0,
          totalItems: 0,
          orderWeight: 0,
          totalShipments: 0,
          totalPrice: 0,
          totalCasesReserved: 0,
          totalEachesReserved: 0,
          totalCasesOrdered: 0,
          totalEachesOrdered: 0,
        };

        // retrieve and filter parent and child (split) submitted/submitting orders from ordersState
        const tandemSplitOrders = (allOrders.ids as Array<string | number>)
          .filter(
            (id: string | number) =>
              selectedCustomer?.customerNumber ===
                allOrders.entities[id]?.orderHeader?.customerNumber &&
              allOrders.entities[id]?.orderHeader?.origOrderId !== null &&
              !!OrderStatusDetails[
                allOrders.entities[id]?.orderHeader?.orderStatus
              ] &&
              OrderStatusDetails[
                allOrders.entities[id]?.orderHeader?.orderStatus
              ].isSubmittedStatus,
          )
          .filter((id: string | number) => {
            for (const [key, value] of Object.entries(
              allOrders.entities[id]?.orderHeader,
            )) {
              if (
                //parent order
                masterOrderById &&
                !!masterOrderById.orderHeader?.orderId &&
                (masterOrderById.orderHeader?.orderId === value ||
                  (masterOrderById.orderHeader?.orderId ===
                    allOrders.entities[id]?.orderHeader?.orderId &&
                    allOrders.entities[id]?.orderHeader?.orderStatus ===
                      OrderStatus.SUBMITTING))
              ) {
                return id; //child order id
              } else if (masterOrderId.split(':')[2] === value) {
                //This condition solves refresh issue or when directly navigate to confirmation page with master order id
                return id;
              }
            }
          })
          .map((id: string | number) => ({
            orderHeader: allOrders.entities[id]?.orderHeader,
            orderItems: allOrders.entities[id]?.orderItems,
          }))
          .sort(
            (a, b) =>
              OrderStatusDetails[a.orderHeader?.orderStatus].sortOrder -
              OrderStatusDetails[b.orderHeader?.orderStatus].sortOrder,
          );

        if (
          masterOrderById?.orderHeader?.origOrderId === null &&
          masterOrderById.orderHeader?.orderStatus === OrderStatus.SUBMITTED
        ) {
          tandemSplitOrders.unshift(masterOrderById);
        }

        if (!!masterOrderById) {
          for (const orderItem of masterOrderById?.orderItems) {
            //For edit submitted order, using price from recently submitted order
            if (
              masterOrderById.orderHeader?.orderStatus === OrderStatus.SUBMITTED
            ) {
              //Price validation: If price available, means item is submitted else get new price for newly added item
              const eachPriceValidation =
                !orderItem.eachPrice == null
                  ? orderItem.eachPrice
                  : pricingState[orderItem.productNumber]?.eachPrice;
              const unitPriceValidation =
                !orderItem.unitPrice == null
                  ? orderItem.eachPrice
                  : pricingState[orderItem.productNumber]?.unitPrice;
              const uomValidation =
                !orderItem.unitPrice == null && !orderItem.unitPrice == null
                  ? orderItem.priceUOM
                  : pricingState[orderItem.productNumber]?.priceUom;
              pricing = {
                eachPrice: eachPriceValidation,
                priceUOM: uomValidation,
                productNumber: orderItem?.productNumber,
                unitPrice: unitPriceValidation,
              };
            } else {
              // For the fresh order, use latest price from the pricing state
              pricing = pricingState[orderItem.productNumber];
            }
          }
        }
        //filtering original order from split array
        const SplittedOrdersWithoutOriginalOrder = tandemSplitOrders?.filter(
          split =>
            split?.orderHeader?.orderStatus !== OrderStatus.SUBMITTING &&
            split?.orderHeader?.orderStatus !== OrderStatus.EDITING_SUBMITTED,
        );

        // add weight & tallies to master order
        const masterOrderForView: MasterOrderForView = new MasterOrderForView();

        masterOrderForView.totalDisplayPrice = 0;
        masterOrderForView.order = masterOrderById;
        masterOrderForView.cartItems = [];
        masterOrderForView.cartProductsItems = [];

        masterOrderById?.orderItems?.forEach(orderItem => {
          let originalProduct: SubbedItem;
          if (autoSubFeatureFlag && orderItem.substituteFlag === 'A') {
            const subbedItemIndex = masterOrderForView.cartItems.findIndex(
              subbedItem =>
                subbedItem?.orderItem?.productNumber ===
                orderItem.substituteFor,
            );
            if (subbedItemIndex > -1) {
              originalProduct = {
                orderItem:
                  masterOrderForView.cartItems[subbedItemIndex]?.orderItem,
                productSummary:
                  masterOrderForView.cartItems[subbedItemIndex]?.productState,
              } as SubbedItem;
              masterOrderForView.cartItems.splice(subbedItemIndex, 1);
            }
          }
          const cartItem = {
            orderItem: orderItem,
            subbedItem: originalProduct,
            productState: productsMap.get(orderItem.productNumber)?.summary,
            productInventory: productsMap.get(orderItem.productNumber)
              ?.inventory,
            productContract: productsMap.get(orderItem.productNumber)?.contract,
            customerPill: productsMap.get(orderItem.productNumber)
              ?.customerPill,
          };
          masterOrderForView.cartItems.push(cartItem);
          masterOrderForView.cartProductsItems.push(cartItem);
        });

        masterOrderForView.hasError =
          masterOrderForView?.order?.orderHeader?.errorDetails !== undefined &&
          masterOrderForView?.order?.orderHeader?.errorDetails !== null;
        masterOrderForView.orderWeight = 0;
        let tandemOrderGrossWeight = 0;
        let tandemOrderEachConversionFactor = 1;
        let tandemUnitOrdered = 0;
        let tandemEachesOrdered = 0;

        for (let index in masterOrderForView?.order?.orderItems) {
          const orderItem = masterOrderForView.order.orderItems[index];
          const productNumber = orderItem.productNumber;
          tandemOrderGrossWeight =
            productsMap?.get(productNumber)?.summary.grossWeight || 0;
          tandemOrderEachConversionFactor =
            productsMap?.get(productNumber)?.inventory.eachConversionFactor ||
            0;
          tandemUnitOrdered = orderItem.unitsOrdered.currentValue
            ? orderItem.unitsOrdered.currentValue
            : 0;
          tandemEachesOrdered = orderItem.eachesOrdered.currentValue
            ? orderItem.eachesOrdered.currentValue
            : 0;
          masterOrderForView.orderWeight += calculateOrderWeight(
            tandemOrderGrossWeight,
            tandemUnitOrdered,
            tandemOrderEachConversionFactor,
            tandemEachesOrdered,
          );
          // Add Tallies to the shipment
          orderTallies.totalItems +=
            (orderItem.unitsOrdered.currentValue || 0) +
            (orderItem.eachesOrdered.currentValue || 0);
          orderTallies.totalCases += orderItem.unitsOrdered.currentValue || 0;
          orderTallies.totalEaches += orderItem.eachesOrdered.currentValue || 0;
        }

        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowsDate = tomorrow.toISOString().split('T')[0];

        if (!!masterOrderForView?.order?.orderHeader?.confirmedDeliveryDate) {
          // Removing timezone from the date string, since the delivery date fluctuates between UTC and CST during submission
          const confirmedDeliveryDate =
            masterOrderForView?.order?.orderHeader?.confirmedDeliveryDate
              ?.toString()
              .slice(0, 10);

          const isDeliveryDateTomorrow =
            confirmedDeliveryDate && confirmedDeliveryDate === tomorrowsDate;

          masterOrderForView.shouldShowAvailableToPromise =
            isDeliveryDateTomorrow &&
            masterOrderForView?.order?.orderHeader?.addOrderSource !== 'VP' &&
            !selectedCustomer.autoSubProducts;
        }
        const splittedOrders: SplittedOrder[] = [];
        if (SplittedOrdersWithoutOriginalOrder?.length) {
          orderTallies.totalCases = 0;
          orderTallies.totalEaches = 0;
          orderTallies.totalItems = 0;
          orderTallies.totalShipments = 0;
          orderTallies.totalPrice = 0;
          orderTallies.orderWeight = 0;
        }
        for (const splittedOrder of SplittedOrdersWithoutOriginalOrder) {
          const eachOrder: SplittedOrder = new SplittedOrder();
          if (
            OrderStatus.SUBMITTED == splittedOrder?.orderHeader?.orderStatus ||
            OrderStatus.SUBMITTED_WITH_EXCEPTIONS ==
              splittedOrder.orderHeader?.orderStatus ||
            OrderStatus.SUBMITTED_CREDIT_HOLD ==
              splittedOrder.orderHeader?.orderStatus
          ) {
            eachOrder.totalDisplayPrice =
              (splittedOrder.orderHeader?.totalDollars || 0) +
                (splittedOrder.orderHeader?.totalSalesTax || 0) +
                (splittedOrder.orderHeader?.totalCharges || 0) -
                Math.abs(splittedOrder.orderHeader?.totalAllowances || 0) || 0;
          } else {
            eachOrder.totalDisplayPrice = 0;
          }
          eachOrder.order = splittedOrder;
          eachOrder.cartItems = [];
          eachOrder.cartProductsItems = [];

          splittedOrder?.orderItems?.forEach(orderItem => {
            let originalProduct: SubbedItem;
            if (autoSubFeatureFlag && orderItem.substituteFlag === 'A') {
              const subbedItemIndex = eachOrder.cartItems.findIndex(
                subbedItem =>
                  subbedItem?.orderItem?.productNumber ===
                  orderItem.substituteFor,
              );
              if (subbedItemIndex > -1) {
                originalProduct = {
                  orderItem: eachOrder.cartItems[subbedItemIndex]?.orderItem,
                  productSummary:
                    eachOrder.cartItems[subbedItemIndex]?.productState,
                } as SubbedItem;
                eachOrder.cartItems.splice(subbedItemIndex, 1);
              }
            }
            const cartItem = {
              orderItem: orderItem,
              subbedItem: originalProduct,
              productState: productsMap.get(orderItem.productNumber)?.summary,
              productInventory: productsMap.get(orderItem.productNumber)
                ?.inventory,
              productContract: productsMap.get(orderItem.productNumber)
                ?.contract,
              customerPill: productsMap.get(orderItem.productNumber)
                ?.customerPill,
            };
            eachOrder.cartItems.push(cartItem);
            eachOrder.cartProductsItems.push(cartItem);
          });

          if (!!splittedOrder?.orderHeader?.confirmedDeliveryDate) {
            const confirmedDeliveryDate =
              splittedOrder?.orderHeader?.confirmedDeliveryDate
                ?.toString()
                .slice(0, 10);

            const isDeliveryDateTomorrow =
              confirmedDeliveryDate && confirmedDeliveryDate === tomorrowsDate;

            eachOrder.shouldShowAvailableToPromise =
              isDeliveryDateTomorrow &&
              splittedOrder?.orderHeader?.addOrderSource !== 'VP' &&
              !selectedCustomer.autoSubProducts;
          }

          eachOrder.hasError =
            splittedOrder.orderHeader?.errorDetails !== undefined &&
            splittedOrder.orderHeader?.errorDetails !== null;
          eachOrder.orderWeight = 0;
          let tandemOrderGrossWeight = 0;
          let tandemOrderEachConversionFactor = 1;
          let tandemUnitOrdered = 0;
          let tandemEachesOrdered = 0;

          for (let index in eachOrder?.order?.orderItems) {
            const orderItem = eachOrder.order.orderItems[index];
            const productNumber = orderItem.productNumber;
            tandemOrderGrossWeight =
              productsMap?.get(productNumber)?.summary.grossWeight || 0;
            tandemOrderEachConversionFactor =
              productsMap?.get(productNumber)?.inventory.eachConversionFactor ||
              0;
            tandemUnitOrdered = orderItem.unitsOrdered.currentValue
              ? orderItem.unitsOrdered.currentValue
              : 0;
            tandemEachesOrdered = orderItem.eachesOrdered.currentValue
              ? orderItem.eachesOrdered.currentValue
              : 0;
            eachOrder.orderWeight += calculateOrderWeight(
              tandemOrderGrossWeight,
              tandemUnitOrdered,
              tandemOrderEachConversionFactor,
              tandemEachesOrdered,
            );
            // Add Tallies to the shipment
            orderTallies.totalItems +=
              (orderItem.unitsOrdered.currentValue || 0) +
              (orderItem.eachesOrdered.currentValue || 0);
            orderTallies.totalCases += orderItem.unitsOrdered.currentValue || 0;
            orderTallies.totalEaches +=
              orderItem.eachesOrdered.currentValue || 0;
            orderTallies.totalCasesReserved += orderItem.unitsReserved || 0;
            orderTallies.totalEachesReserved += orderItem.eachesReserved || 0;
            orderTallies.totalCasesOrdered +=
              orderItem.unitsOrdered?.currentValue || 0;
            orderTallies.totalEachesOrdered +=
              orderItem.eachesOrdered?.currentValue || 0;
          }
          splittedOrders.push(eachOrder);
        }

        orderTallies.totalShipments = splittedOrders?.length;
        // Move routed order to index 0 of splittedOrders if it exists
        const routedOrderIndex = splittedOrders.findIndex(
          splitOrder => splitOrder.order?.orderHeader?.orderType === 'RT',
        );
        if (routedOrderIndex > -1) {
          const routedOrder = { ...splittedOrders[routedOrderIndex] };
          splittedOrders.splice(routedOrderIndex, 1);
          splittedOrders.unshift(routedOrder);
        }
        // Calculate the totalPrice field for the Master Order
        orderTallies.totalPrice = splittedOrders.reduce((acc, splitOrder) => {
          return acc + splitOrder.totalDisplayPrice;
        }, 0);

        const expectedShipmentCount =
          OrderService.calculateExpectedShipmentCount(
            masterOrderById,
            productsMap,
          );
        const shipToCustomer =
          masterOrderById?.orderHeader?.shipToCustomer !==
            masterOrderById?.orderHeader?.customerNumber &&
          customers?.find(
            cust =>
              cust?.customerNumber ===
              masterOrderById?.orderHeader?.shipToCustomer,
          );

        const shipToDepartmentName =
          masterOrderById?.orderHeader?.shipToDepartment &&
          shipToCustomer?.departments?.find(
            dept =>
              Number(dept?.departmentNumber) ===
              masterOrderById?.orderHeader?.shipToDepartment,
          )?.departmentName;
        /** orderItem obj below will be empty when directly navigate to the confirmation page without submitting the order */
        const orderConfirmation: OrderConfirmationVM = {
          masterOrderById,
          masterOrderForView,
          orderTallies,
          customerProfile: selectedCustomer,
          splittedOrders,
          ready: allOrders?.ready,
          expectedShipmentCount,
          showTransferButton:
            !!partnerState?.punchoutProfile &&
            !partnerState.punchoutProfile.autoTransfer &&
            !sessionStorage.getItem('isPartnerModifyingOrder') &&
            !partnerState.isPunchthru,
          transferButtonText: partnerState?.punchoutProfile?.transferButton,
          partnerAutotransfer:
            partnerState?.punchoutProfile?.autoTransfer &&
            !!sessionStorage.getItem('autoTransferOrder') &&
            !sessionStorage.getItem('isPartnerModifyingOrder'),
          disableTransfer:
            expectedShipmentCount > splittedOrders.length ||
            ordersSubmissionIncomplete(splittedOrders),
          navigationRestricted:
            partnerState?.siteRoleProfile?.siteRestricted ||
            partnerState?.siteRoleProfile?.exceptionRestricted,
          exceptionRestricted:
            partnerState?.siteRoleProfile?.exceptionRestricted,
          shipToCustomer,
          shipToDepartmentName,
        };
        return orderConfirmation;
      }
    },
  );

export const ordersSubmissionIncomplete = (
  splittedOrders: SplittedOrder[],
): boolean => {
  return !splittedOrders.every(
    order =>
      order.order.orderHeader.orderStatus === OrderStatus.SUBMITTED ||
      order.order.orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD,
  );
};

export const calculateOrderWeight = (
  grossWeight: number,
  cases: number,
  convFactor: number,
  eaches: number,
): number => {
  const grossMulCases = grossWeight * cases;
  const dividedByConv = !!convFactor ? grossWeight / convFactor : 0;
  const convMulEaches = dividedByConv * eaches;
  const wgt = grossMulCases + convMulEaches;
  return wgt;
};
