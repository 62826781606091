import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '@order/services/order.service';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { DeliveryStoreService, NextDeliveryDate } from '@usf/ngrx-order';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  combineLatest,
  firstValueFrom,
} from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { HomeService } from 'src/app/home/services/home.service';
import { LoginService } from 'src/app/login/services';
import { NotificationsService } from 'src/app/notifications/services/notifications.service';
import { UserCustomerInfo } from '@app/home/models/user-customer-info.model';
import { AlertMessages } from '../../constants/alert-messages.enum';
import { PATHS } from '../../constants/paths';
import { FEATURES } from '../../constants/splitio-features';
import { NavigationHelperService } from '../../helpers/navigation.helpers.service';
import { PopoverHelperService } from '../../helpers/popover.helpers';
import { WarningPopoverComponent } from '../popovers/warning-popover/warning-popover.component';
import { HeaderViewModel } from './header-view.model';
import { HeaderService } from './header.service';
import { DetailHistoryService } from './history/detail-history.service';
import { ModalService } from '@shared/services/modal/modal.service';
import { OrderSyncingComponent } from '../popovers/order-syncing/order-syncing.component';
import { NetworkStatus } from '../../models/network-status.model';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { CustomerSelectionOption } from '@app/customer/models/customer-selection-option.model';
import { CustomerService } from '@app/customer/services/customer.service';
import { UserService } from '@app/user/services';
import { ListUpdatesService } from '@app/lists/services/list-updates.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  // INPUTS
  @Input() hideBackButton = false;
  @Input() isAlerts?: boolean;
  @Input() isInventory?: boolean;
  @Input() inventoryValue?: string;
  zipCodeValue: number;

  // OBSERVABLES
  viewModel$: Observable<HeaderViewModel>;
  isSuperUser$: Observable<boolean>;
  userCustomerInfo$: Observable<UserCustomerInfo>;
  totalPrice$: Observable<number>;
  customerSwitchStatus$: Observable<string>;

  // VARIABLES
  readonly PATHS = PATHS;
  isOnline: NetworkStatus['isOnline'];
  messageBarText = '';
  previousUrl: string;
  shouldDisplayMessageBar = false;
  unreadNotifications: number;
  toogleNewSearchFeatureFlag = FEATURES.split_user_toggle_new_search;

  // SUBSCRIPTIONS/BEHAVIOR SUBJECTS
  searchText$ = new BehaviorSubject<string>('');
  dataForHeaderSub$: Subscription = new Subscription();
  featureFlagRestrictedSub$: Subscription = new Subscription();
  previousUrlSub$: Subscription = new Subscription();
  routerEventsSub$: Subscription = new Subscription();
  unreadNotificationsSub$: Subscription = new Subscription();
  zipCodeSub$: Subscription;

  isGuestUser = false;
  isGuestUser$: Observable<boolean>;
  listUpdatesExist$: Observable<boolean>;

  constructor(
    protected modalController: ModalController,
    protected popoverController: PopoverController,
    readonly panAppState: PanAppState,
    protected customerService: CustomerService,
    private customerStoreService: CustomerStoreService,
    private deliveryStoreService: DeliveryStoreService,
    protected detailHistoryService: DetailHistoryService,
    private headerService: HeaderService,
    private homeService: HomeService,
    protected loginService: LoginService,
    protected modalService: ModalService,
    protected navigationHelperService: NavigationHelperService,
    private notificationsService: NotificationsService,
    protected orderService: OrderService,
    readonly platformService: PlatformService,
    private popoverHelperService: PopoverHelperService,
    private translateService: TranslateService,
    private userService: UserService,
    private listUpdateService: ListUpdatesService,
  ) {}

  ngOnInit() {
    this.isGuestUser$ = this.userService
      .isGuestUser$()
      .pipe(tap(isGuestUser => (this.isGuestUser = isGuestUser)));
    this.listUpdatesExist$ = this.listUpdateService.listUpdatesExist$();
    this.setHeaderViewModel();
    this.setDataForHeader();
    this.customerSwitchStatus$ =
      this.customerStoreService.customerSwitchStatus$();
  }

  setHeaderViewModel() {
    this.viewModel$ = this.headerService.getHeaderViewModel();
  }

  setDataForHeader() {
    this.userCustomerInfo$ = this.homeService.loadUserCustomer$();
    this.isSuperUser$ = this.userService.isSuperUser$();
    this.dataForHeaderSub$ = combineLatest([
      this.panAppState.online$,
      this.userCustomerInfo$,
      this.deliveryStoreService.getNextDeliveryInformation(),
    ]).subscribe(([isOnline, userCustomerInfo, nextDeliveryInfo]) => {
      this.isOnline = isOnline;
      this.updateMessageBarDetails(
        isOnline,
        userCustomerInfo,
        nextDeliveryInfo,
      );
    });

    this.setUnreadNotifications();
    this.setRestrictedFeatureFlagStatus();
    this.setPreviousUrl();
    this.setTotalPrice();
  }

  private updateMessageBarDetails(
    isOnline: boolean,
    userCustomerInfo: UserCustomerInfo,
    nextDeliveryInfo: NextDeliveryDate,
  ) {
    this.shouldDisplayMessageBar = false;
    this.messageBarText = '';

    const hasDeliveryError =
      nextDeliveryInfo?.errNumber !== null &&
      nextDeliveryInfo?.errNumber !== undefined;
    const hasCreditOnHold =
      !nextDeliveryInfo?.loading && !nextDeliveryInfo?.creditIndicator;
    const hasNextDeliveryHttpError =
      nextDeliveryInfo?.error?.error?.status < 200 ||
      nextDeliveryInfo?.error?.error?.status >= 300;
    const isRestrictedFlagEnabled = this.setRestrictedFeatureFlagStatus();
    let isCustomerRestrictedOG = false;

    if (isRestrictedFlagEnabled) {
      isCustomerRestrictedOG =
        userCustomerInfo?.selectedCustomer?.restrictToOG === 'M' ||
        userCustomerInfo?.selectedCustomer?.restrictToOG === 'Y' ||
        userCustomerInfo?.selectedCustomer?.customerType === 'NA';
    }
    const hasAlertsError = this.unreadNotifications === -1;

    if (!isOnline) {
      this.shouldDisplayMessageBar = true;
      this.messageBarText = this.translateService.instant(
        'i18n.home.' + AlertMessages.networkStatusMessage,
      );
    } else {
      if (hasDeliveryError && !this.isGuestUser) {
        this.shouldDisplayMessageBar = true;
        this.messageBarText = this.translateService.instant(
          'i18n.home.' + AlertMessages.technicalErrorMessage,
        );
      }
      if (isCustomerRestrictedOG) {
        this.shouldDisplayMessageBar = true;
        this.messageBarText = this.translateService.instant(
          'i18n.home.' + AlertMessages.customerRestrictedOGMessage,
        );
      }
      if (hasAlertsError) {
        this.shouldDisplayMessageBar = true;
        this.messageBarText = this.translateService.instant(
          'i18n.home.' + AlertMessages.alertsErrorMessage,
        );
      }
      if (hasCreditOnHold && !this.isGuestUser) {
        this.shouldDisplayMessageBar = true;
        this.messageBarText = this.translateService.instant(
          'i18n.home.' + AlertMessages.technicalErrorMessage,
        );
      }
      if (hasNextDeliveryHttpError && !this.isGuestUser) {
        this.shouldDisplayMessageBar = true;
        this.messageBarText = this.translateService.instant(
          'i18n.home.' + AlertMessages.technicalErrorMessageOrdering,
        );
      }
    }
  }

  setUnreadNotifications() {
    this.unreadNotificationsSub$ = this.notificationsService
      .getUnreadTotalCount$()
      ?.subscribe(count => {
        this.unreadNotifications = count;
      });
  }

  setRestrictedFeatureFlagStatus() {
    let isFlagOn: boolean;
    this.featureFlagRestrictedSub$ = this.panAppState
      .feature$([FEATURES.split_global_national_customers])
      ?.subscribe(on => (isFlagOn = on));

    return isFlagOn;
  }

  setPreviousUrl() {
    this.previousUrlSub$ = this.panAppState?.previousUrl$.subscribe(
      previousUrl => {
        this.previousUrl =
          previousUrl === '' || typeof previousUrl === undefined
            ? this.detailHistoryService.getPreviousUrl()
            : previousUrl;
      },
    );
  }

  setTotalPrice() {
    this.totalPrice$ = this.orderService.orderTotalPrice();
  }

  routeTo(path: string, queryParams = {}) {
    this.navigationHelperService.routeTo(path, queryParams);
  }

  async openWarningPopover() {
    await this.popoverHelperService.presentPopover(
      this.popoverController,
      WarningPopoverComponent,
      this.translateService.instant('i18n.header.offlineWarning'),
    );
  }

  protected getCustomerSelectionVM$() {
    return this.homeService.getCustomerSelectionVM$(this.searchText$);
  }

  setSuperUserSelectedCustomer(customer: CustomerSelectionOption) {
    this.changeCurrentCustomer(
      customer.customerNumber,
      Number(customer.departmentNumber),
      customer.divisionNumber,
    );
    this.customerService.setIsSuperUserCustomersModalOpen(false);
  }

  setSuperUserFavoriteCustomer(customer: CustomerSelectionOption) {
    customer.isFavorite = !customer.isFavorite;
  }

  protected onCustomerDropdownDismiss(data: any) {
    const { customerNumber, departmentNumber } = data;
    this.orderService
      .shouldShowCartSyncingPopover$()
      .pipe(take(1))
      .subscribe(async showPopover => {
        if (showPopover) {
          await this.openOrderSyncingModal(
            customerNumber,
            parseInt(departmentNumber),
          );
        } else {
          this.getNewCustomer(customerNumber, parseInt(departmentNumber));
        }
      });
  }

  async openOrderSyncingModal(
    customerNumber: number,
    departmentNumber: number,
  ) {
    this.modalService.setModalOptions(
      this.platformService?.isTouch.value,
      OrderSyncingComponent,
      { customerSwitch: true, orderSwitch: false },
      'simple-action-modal',
    );

    const modal = await this.modalController.create(
      this.modalService.modalOptions,
    );
    modal.onDidDismiss().then(resp => {
      if (resp?.data?.continueClick) {
        this.getNewCustomer(customerNumber, departmentNumber);
      }
    });

    return await modal.present();
  }

  private async getNewCustomer(
    customerNumber: number,
    departmentNumber: number,
  ) {
    const customer = await firstValueFrom(
      this.homeService.getCustomerWithCustomerNumber(customerNumber),
    );

    const session = await firstValueFrom(this.panAppState.session$);
    if (session.isGuest && !!customer) {
      const zipStr = customer.zip?.toString();
      if (!!zipStr) {
        //In future, this action will be triggered by a zip code selection so will always be present
        const zipCode = zipStr.substring(0, zipStr.length - 4);
        this.loginService.switchGuestZip(+zipCode);
        this.routeTo(PATHS.HOME);
        this.resetHomeDisplay();
      }
    } else if (customer) {
      this.changeCurrentCustomer(
        customer.customerNumber,
        departmentNumber,
        customer.divisionNumber,
      );
    }
  }

  private async changeCurrentCustomer(
    customerNumber: number,
    departmentNumber: number,
    divisionNumber: number,
  ) {
    await this.loginService.switchToCustomer(
      customerNumber,
      departmentNumber,
      divisionNumber,
    );

    const currentUrl = await firstValueFrom(this.panAppState.currentUrl$);
    if (currentUrl) {
      if (
        currentUrl.indexOf('notifications') === -1 &&
        currentUrl.indexOf('payments') === -1
      ) {
        this.routeTo(PATHS.HOME);
      }
      if (currentUrl.indexOf('lists') !== -1) {
        this.routeTo(PATHS.LISTS, { getChanges: false });
      }
    }

    await this.resetHomeDisplay();
  }

  private async resetHomeDisplay() {
    const homeSwiperRef = await firstValueFrom(this.homeService.homeSwiperRef$);
    if (homeSwiperRef) {
      if (
        homeSwiperRef &&
        !homeSwiperRef.isBeginning &&
        !homeSwiperRef.destroyed
      ) {
        homeSwiperRef.slideTo(0);
      }
    }

    if (document.getElementsByClassName('home-banner')[0]) {
      document
        .getElementsByClassName('home-banner')[0]
        .scrollIntoView({ block: 'end' });
    }
  }

  ngOnDestroy() {
    if (this.dataForHeaderSub$) {
      this.dataForHeaderSub$.unsubscribe();
    }
    if (this.featureFlagRestrictedSub$) {
      this.featureFlagRestrictedSub$.unsubscribe();
    }
    if (this.previousUrlSub$) {
      this.previousUrlSub$.unsubscribe();
    }
    if (this.routerEventsSub$) {
      this.routerEventsSub$.unsubscribe();
    }
    if (this.unreadNotificationsSub$) {
      this.unreadNotificationsSub$.unsubscribe();
    }
  }
}
