import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ListAnalyticsHelperService } from '@app/lists/services/list-analytics-helper.service';
import {
  ListRow,
  ProductRow,
} from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { CustomerStoreService } from '@app/ngrx-customer/services';
import { selectedCustomer } from '@app/ngrx-customer/store';
import { MessageStoreService } from '@app/ngrx-message/services/message/message-store.service';
import { Capacitor } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UsfTokenStorageService } from '@panamax/app-state';
import { desiredColumn } from '@shared/models/desired-column';
import { SeperatedColumn } from '@shared/models/seperated-columns';
import { SentenceCasePipe } from '@shared/pipes/sentence-case.pipe';
import { LoadingSpinnerService } from '@shared/services/loading-spinner/loading-spinner.service';
import { ToastService } from '@shared/services/toast/toast.service';
import {
  DownloadRequestFileType,
  DownloadRequestListType,
  ImportErrorDetail,
} from '@usf/list-types';
import { first } from 'rxjs';
import { DownloadPrintBaseService } from '../download-print-base.service';
import {
  documentFunctionMap,
  filterOutGroupItemTypes,
  filterOutProductItemTypes,
} from '../helpers/download-list-helper';
import {
  createHeadersForPDF,
  createProductRows,
} from '../helpers/general-pdf-helper';
import { getASCIIColumns, stringifyASCII } from '../helpers/list-ascii-helper';
import { getCSVColumns, stringifyCSV } from '../helpers/list-csv-helper';
import {
  collectGroupNames,
  collectGroupRows,
  getPDFColumns,
  pdfToBlob,
} from '../helpers/list-pdf-helper';
import { getPRICEColumns, stringifyPRICE } from '../helpers/list-price-helper';
import { getXMLColumns, stringifyXML } from '../helpers/list-xml-helper';
import { Papa } from 'ngx-papaparse';
import { utils, write } from 'xlsx';
import { b64toBlob } from '@shared/helpers/file.helpers';
import { ServiceHandlerService } from '@shared/services/service-handler.service';
import { DownloadListOptions } from '@usf/ngrx-list';
import { ListTypes } from '../../../constants/lists-constants';
import { ProductDetail } from '@usf/product-types';
import { nutritonalDocumentMap } from '../helpers/download-product-helper';
import { ProductNutritionFacts } from '@usf/file-share-utils';
import * as nutritionalInfoUtil from '@product-detail/utils/nutritional-info.util';
import { extractClaims } from '@product-detail/utils/product-claims-util';

@Injectable({
  providedIn: 'root',
})
export class DownloadListService extends DownloadPrintBaseService {
  constructor(
    http: HttpClient,
    toastService: ToastService,
    translateService: TranslateService,
    platform: Platform,
    datePipe: DatePipe,
    loadingSpinnerService: LoadingSpinnerService,
    listAnalyticsHelperService: ListAnalyticsHelperService,
    sentenceCasePipe: SentenceCasePipe,
    private store: Store,
    private customerStoreService: CustomerStoreService,
    private tokenStorageService: UsfTokenStorageService,
    private serviceHandler: ServiceHandlerService,
    messageStoreService: MessageStoreService,
    private papa: Papa,
  ) {
    super(
      http,
      toastService,
      translateService,
      platform,
      datePipe,
      loadingSpinnerService,
      listAnalyticsHelperService,
      sentenceCasePipe,
      messageStoreService,
    );
  }

  public donwloadImportTemplate = async () => {
    this.http
      .get('assets/documents/example/List_Mgmt_import_list_template.csv', {
        responseType: 'text',
      })
      .pipe(first())
      .subscribe(data =>
        this.saveDocument(data, 'List_Mgmt_import_list_template', 'CSV', true),
      );
  };

  public downloadImportErrors = async (
    importErrors: ImportErrorDetail[],
    listName: string,
  ) => {
    const errorsforPapa = importErrors.map(errorDetail => {
      return ['' + errorDetail.rowNumber, errorDetail.errorMessage];
    });
    errorsforPapa.unshift(['Row Number', 'Error Description']);
    const data = this.papa.unparse(errorsforPapa);
    this.saveDocument(data, 'ImportListErrors-' + listName, 'CSV', true);
  };

  public downloadList = async (
    downloadListOptions: DownloadListOptions,
    listState: string,
    listRows: ListRow[],
    details: ProductDetail[],
  ) => {
    try {
      this.trackDownloadPrintAnalytics(
        downloadListOptions.listId,
        downloadListOptions.listTypeId,
        listState,
        'download:list:menu',
      );

      switch (downloadListOptions.format) {
        case 'CSV':
          await this.generateAndSaveCSV(downloadListOptions, listRows, details);
          break;

        case 'XLSX':
          await this.generateAndSaveXLSX(
            downloadListOptions,
            listRows,
            details,
          );
          break;

        case 'XML':
          await this.generateAndSaveXML(downloadListOptions, listRows);
          break;

        case 'PDF':
          await this.generateAndSavePDF(downloadListOptions, listRows);
          break;

        case 'Price File':
          await this.generateAndSavePRICEFile(downloadListOptions, listRows);
          break;

        case 'Price File ASCII':
          await this.generateAndSaveASCIIFile(downloadListOptions, listRows);
          break;

        default:
          this.cleanUp();
          break;
      }
    } catch (error) {
      this.cleanUp();
      this.showErrorToast(
        downloadListOptions.isDownload
          ? 'i18n.lists.downloadError'
          : 'i18n.lists.printListError',
      );
      console.error('Error creating file', error);
    }
  };

  generateAndSavePDF = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
  ) => {
    const isMobile = Capacitor.isNativePlatform();
    const columnsConfig = getPDFColumns(downloadListOptions, isMobile);
    const productsOnly = filterOutGroupItemTypes(listRows);
    const groupNamesInOrder = collectGroupRows(
      filterOutProductItemTypes(listRows),
    );
    const groupNamesForProducts = collectGroupNames(productsOnly);
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      productsOnly,
      columnsConfig,
      'PDF',
    );

    const productRowsAndNeededImages = createProductRows(
      seperatedColumns,
      groupNamesForProducts,
      isMobile,
    );
    const headers = createHeadersForPDF(columnsConfig);
    const isRecentPurchase =
      downloadListOptions.listTypeId === ListTypes.recentlyPurchased;
    // need to adjust image index based on if an optional column is not present before it

    let imageIndex = [];
    let startingIndex = 4;

    if (isRecentPurchase) startingIndex--;
    if (!downloadListOptions.includeProductNotes) startingIndex--;

    if (downloadListOptions.includeProductStatus && !isMobile) {
      imageIndex.push(startingIndex);
      startingIndex++;
    }

    if (downloadListOptions.includeProductType && !isMobile) {
      imageIndex.push(startingIndex);
      startingIndex++;
    }

    this.store
      .select(selectedCustomer)
      .pipe(first())
      .subscribe(async customer => {
        this.customerStoreService
          .loadDivisionByDivisionNumber$(customer.divisionNumber)
          .pipe(first())
          .subscribe(async division => {
            this.getToken$()
              .pipe(first())
              .subscribe(async token => {
                const departmentNumber = token.departmentNumber;
                const pdf = await pdfToBlob(
                  headers,
                  productRowsAndNeededImages.productRowsByGroup,
                  groupNamesInOrder,
                  columnsConfig,
                  downloadListOptions.fileName,
                  imageIndex,
                  customer,
                  productsOnly.length,
                  division.divisionName,
                  downloadListOptions.listName,
                  departmentNumber,
                  productRowsAndNeededImages.imageKeys,
                  isMobile,
                  this.serviceHandler,
                );
                await this.saveDocument(
                  pdf,
                  downloadListOptions.fileName,
                  'PDF',
                  downloadListOptions.isDownload,
                );
              });
          });
      });
  };

  /**
   *
   * @param fileName User generated name for the file
   * @param listRows Array of list rows
   * @param includePoductPrices hide price column in report
   * @returns void
   */
  generateAndSaveCSV = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
    details: ProductDetail[],
  ) => {
    const csv = this.generateCSV(listRows, downloadListOptions, details);
    await this.saveDocument(
      csv,
      downloadListOptions.fileName,
      'CSV',
      downloadListOptions.isDownload,
    );
  };

  private generateCSV(
    listRows: ListRow[],
    downloadListOptions: DownloadListOptions,
    details: ProductDetail[],
  ) {
    let filteredResponse = filterOutGroupItemTypes(listRows);
    if (downloadListOptions.includeNutritionals) {
      let detailMap = new Map<number, ProductDetail>();
      details.forEach(detail => {
        detailMap.set(detail.productNumber, detail);
      });
      filteredResponse = filteredResponse.map(product => {
        let detail = detailMap.get(product.productNumber);
        detail = {
          ...detail,
          claims: extractClaims(detail?.claims),
        };
        let nutritionFacts: ProductNutritionFacts = {
          supplementalFacts: [],
          calories: 0,
          servingSizeData: '',
          nutrientsData: [],
          nutrientIsNotAvailable: false,
        };

        let nutrients = detail?.listPIMNutrientsProduction;
        nutrients = nutritionalInfoUtil.filterNutrientsByPriority(nutrients);
        nutritionFacts.supplementalFacts =
          nutritionalInfoUtil.extractSupplementalFacts(nutrients);
        nutritionFacts.calories =
          nutritionalInfoUtil.extractCalories(nutrients);
        nutritionFacts.servingSizeData =
          nutritionalInfoUtil.extractServingSize(nutrients);
        nutritionFacts.nutrientsData =
          nutritionalInfoUtil.extractNutrients(nutrients);
        nutritionFacts.nutrientIsNotAvailable =
          !nutritionalInfoUtil.isNutrientDataAvailable(nutrients);

        return {
          ...product,
          detail,
          nutritionFacts,
        };
      });
    }
    const columnsConfig = getCSVColumns(downloadListOptions);
    let seperatedColumns;
    if (downloadListOptions.includeNutritionals) {
      seperatedColumns =
        this.createMultipleSeperatedColumnsForListWithNutritionals(
          filteredResponse,
          columnsConfig,
          'CSV',
        );
    } else {
      seperatedColumns = this.createMultipleSeperatedColumnsForList(
        filteredResponse,
        columnsConfig,
        'CSV',
      );
    }

    return stringifyCSV(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
    );
  }

  /**
   *
   * @param fileName User generated name for the file
   * @param listRows Array of list rows
   * @param includePoductPrices hide price column in report
   * @returns void
   */
  generateAndSaveXLSX = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
    details: ProductDetail[],
  ) => {
    const csv = this.generateCSV(listRows, downloadListOptions, details);

    const parsedCSV = this.papa.parse(csv, { header: false });
    const excelData = parsedCSV.data;

    // Create a new XLSX workbook and worksheet
    const workbook = utils.book_new();
    const worksheet = utils.aoa_to_sheet(excelData);

    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const xlsxData = write(workbook, { compression: true, type: 'base64' });

    const xlsxBlob = b64toBlob(xlsxData);

    await this.saveDocument(
      xlsxBlob,
      downloadListOptions.fileName,
      'XLSX',
      downloadListOptions.isDownload,
    );
  };

  generateAndSaveXML = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getXMLColumns(downloadListOptions);
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'XML',
    );
    const xml = stringifyXML(seperatedColumns);
    await this.saveDocument(
      xml,
      downloadListOptions.fileName,
      'XML',
      downloadListOptions.isDownload,
    );
  };

  generateAndSavePRICEFile = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getPRICEColumns(downloadListOptions);
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'Price File',
    );
    const csv = stringifyPRICE(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
      downloadListOptions.includeProductPrices,
    );
    await this.saveDocument(
      csv,
      downloadListOptions.fileName,
      'Price File',
      downloadListOptions.isDownload,
    );
  };

  generateAndSaveASCIIFile = async (
    downloadListOptions: DownloadListOptions,
    listRows: ListRow[],
  ) => {
    const filteredResponse = filterOutGroupItemTypes(listRows);
    const columnsConfig = getASCIIColumns(downloadListOptions);
    const seperatedColumns = this.createMultipleSeperatedColumnsForList(
      filteredResponse,
      columnsConfig,
      'Price File ASCII',
    );
    const ascii = stringifyASCII(
      seperatedColumns,
      seperatedColumns[0].columnValues.length,
      ',',
      downloadListOptions.includeProductPrices,
    );
    await this.saveDocument(
      ascii,
      downloadListOptions.fileName,
      'Price File ASCII',
      downloadListOptions.isDownload,
    );
  };

  // WILL NEED TO ADJUST IF LIST ENUMS GROW PAST 27
  createMultipleSeperatedColumnsForListWithNutritionals(
    products: ProductRow[],
    columnConfig: desiredColumn[],
    fileType: DownloadRequestFileType,
  ): SeperatedColumn[] {
    let columnMap = new Map<string, SeperatedColumn>();

    products.forEach((productRow: any) => {
      columnConfig.forEach(column => {
        if (column.columnType > 27) {
          columnMap = nutritonalDocumentMap[column.columnType](
            column.columnName,
            columnMap,
            productRow,
            fileType,
          );
        } else {
          columnMap = documentFunctionMap[column.columnType](
            column.columnName,
            columnMap,
            productRow,
            fileType,
          );
        }
      });
    });

    let seperatedColumns: SeperatedColumn[] = [];
    columnMap.forEach(column => {
      seperatedColumns.push(column);
    });

    return seperatedColumns;
  }

  /**
   *
   * @param listRows an array of list item rows.
   * @returns SeperatedColumn[]
   */
  createMultipleSeperatedColumnsForList(
    products: ProductRow[],
    columnConfig: desiredColumn[],
    fileType: DownloadRequestFileType,
  ): SeperatedColumn[] {
    let columnMap = new Map<string, SeperatedColumn>();

    products.forEach((productRow: any) => {
      columnConfig.forEach(column => {
        columnMap = documentFunctionMap[column.columnType](
          column.columnName,
          columnMap,
          productRow,
          fileType,
        );
      });
    });

    let seperatedColumns: SeperatedColumn[] = [];
    columnMap.forEach(column => {
      seperatedColumns.push(column);
    });

    return seperatedColumns;
  }

  getToken$ = () => {
    return this.tokenStorageService.getContext();
  };
}
