import { EventEmitter, Injectable } from '@angular/core';
import { PlatformEnum, PlatformService } from '@panamax/app-state';
import { DualPaneModalComponent } from '@shared/components/dual-pane/dual-pane-modal/dual-pane-modal.component';
import { DualPaneView } from '@shared/constants/dual-pane.enum';
import {
  BasicListItem,
  DualPaneFlagsConfig,
  DualPaneFunctionsConfig,
  DualPaneLabelsConfig,
  DualPaneModalData,
  DualPaneViewModel,
  LeftPaneInfo,
} from '@shared/models/dual-pane-model';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BaseDualPaneModalService {
  public isModalOpen$ = new BehaviorSubject(false);
  public submit$ = new BehaviorSubject<Map<string, any | any[]>>(undefined);
  isLoading$: Observable<boolean> = of(false);
  outsideLifecycle$ = new BehaviorSubject(undefined);
  public dualPaneModalData: DualPaneModalData;
  public dualPaneView: DualPaneView;
  public dualPaneRef = DualPaneModalComponent;

  submitEvent = new EventEmitter();

  constructor(public platformService: PlatformService) {}

  buildBasePaneWithConfigs = (
    labelConfig: DualPaneLabelsConfig,
    functionConfig: DualPaneFunctionsConfig,
    flagsConfig: DualPaneFlagsConfig,
    leftPaneItems: BasicListItem[],
  ) => {
    const initialRightView =
      this.platformService.platformType !== PlatformEnum.mobile
        ? functionConfig.intialDesktopTabletView({} as DualPaneViewModel, '')
        : { view: DualPaneView.noView };

    this.dualPaneModalData = {
      dataCyTag: labelConfig.dataCy,
      viewModel: {
        title: labelConfig.title,
        subTitle: labelConfig.subTitle,
        complexSubTitle: labelConfig.complexSubTitle,
        htmlSubTitle: labelConfig.htmlSubTitle,
        navigationPageCount: 0,
        leftView: {
          view: DualPaneView.basicList,
          content: leftPaneItems as BasicListItem[],
        } as LeftPaneInfo,
        rightView: initialRightView,
        disableSubmit: true,
        disableSave: true,
        submitText: labelConfig.submitText,
        submitIsBackButton: false,
        backText: labelConfig.backText,
        isLoading$: this.isLoading$,
        handleSubmission: functionConfig.handleSubmit,
        verifySubmissionValidity: functionConfig.verifySubmit,
        platform: this.platformService.platformType,
        dismissOnSubmit: flagsConfig.dismissOnSubmit ?? false,
        overrideDismiss: functionConfig.overrideDismiss,
        outsideLifecycleOperation: functionConfig.outsideLifecycleOperation,
      } as DualPaneViewModel,
      platform: this.platformService.platformType,
      modalOpenState$: this.isModalOpen$,
      submit$: this.submit$,
      outsideLifecycle$: this.outsideLifecycle$,
    };
  };
}
