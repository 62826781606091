import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';

@Directive({
  selector: '[appHeaderAddShrinkAnimationOnClick]',
})
export class HeaderIconShrinkAnimateDirective implements OnDestroy {
  private isAnimating = false; // Track if animation is in progress

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  @HostListener('click')
  onClick() {
    // If animation is already in progress, ignore subsequent clicks
    if (this.isAnimating) {
      return;
    }

    // Set flag to indicate animation is in progress
    this.isAnimating = true;

    // Add the animation class
    this.renderer.addClass(this.el.nativeElement, 'shrink-expand');

    // Ensure the animation class is applied by triggering a reflow (force browser to register)
    void this.el.nativeElement.offsetWidth; // Forces reflow to apply animation correctly

    // Listen for the end of the animation
    this.el.nativeElement.addEventListener(
      'animationend',
      this.onAnimationEnd.bind(this),
    );
  }

  private onAnimationEnd() {
    // Remove the animation class after it finishes
    this.renderer.removeClass(this.el.nativeElement, 'shrink-expand');
    this.isAnimating = false; // Reset the flag after the animation completes

    // Clean up the event listener after the animation
    this.el.nativeElement.removeEventListener(
      'animationend',
      this.onAnimationEnd.bind(this),
    );
  }

  ngOnDestroy() {
    if (this.isAnimating) {
      this.el.nativeElement.removeEventListener(
        'animationend',
        this.onAnimationEnd.bind(this),
      );
    }
  }
}
