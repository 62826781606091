import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PanAppState } from '@panamax/app-state';
import { CoveoToken } from '../../models/coveo/coveo-token.model';
import { environment } from 'src/environments/environment';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  concatMap,
  filter,
  firstValueFrom,
  map,
  of,
  Subscription,
} from 'rxjs';
import { FEATURES } from '@shared/constants/splitio-features';
import { SearchEngine } from '@coveo/headless';
import { COVEO_TOKEN_LOCAL_STORAGE_KEY } from '@shared/constants/coveo-constants';
import { ToggleNewSearchService } from '../toggle-new-search/toggle-new-search.service';
import { buildCommerceEngine, CommerceEngine } from '@coveo/headless/commerce';
import { SessionState } from '@panamax/app-state/lib/models/session.model';

@Injectable({
  providedIn: 'root',
})
export class CoveoService {
  constructor(
    private panAppState: PanAppState,
    private toggleNewSearchService: ToggleNewSearchService,
    private http: HttpClient,
  ) {}

  coveoTokenSubscription$: Subscription;
  commerceEngineSubscription$: Subscription;
  previousSession: SessionState;
  private coveoCommerceEngine: CommerceEngine;
  searchEngineSubject$: BehaviorSubject<CommerceEngine> = new BehaviorSubject(
    null,
  );
  commerceEngineSubject$: BehaviorSubject<CommerceEngine> = new BehaviorSubject(
    null,
  );

  async getRefreshToken(): Promise<string> {
    try {
      const coveoResponse = await firstValueFrom(
        this.http.get<CoveoToken>(`${environment.coveoTokenUrl}`).pipe(
          map(coveoResponse => {
            localStorage.setItem(
              COVEO_TOKEN_LOCAL_STORAGE_KEY,
              coveoResponse.coveoToken,
            );
            return coveoResponse.coveoToken;
          }),
          catchError(err => {
            return of(null);
          }),
        ),
      );
      return coveoResponse;
    } catch (error) {
      throw error;
    }
  }

  getCoveoToken$() {
    return this.http.get<CoveoToken>(`${environment.coveoTokenUrl}`).pipe(
      map(coveoResponse => {
        localStorage.setItem(
          COVEO_TOKEN_LOCAL_STORAGE_KEY,
          coveoResponse.coveoToken,
        );
        this.initializeCommerceEngine(coveoResponse.coveoToken);
        return coveoResponse.coveoToken;
      }),
      catchError(err => of(err)),
    );
  }

  init() {
    this.coveoTokenSubscription$ = combineLatest([
      this.panAppState.session$,
      this.toggleNewSearchService.isNewSearchEnabled(),
      this.panAppState.feature$([
        FEATURES.split_global_search_new_search_analytics,
      ]),
    ])
      .pipe(
        filter(
          ([_, newSearchEnabled, newSearchAnalyticsFlag]) =>
            newSearchEnabled || newSearchAnalyticsFlag,
        ),
        concatMap(([session]) => {
          if (
            !this.previousSession ||
            session?.selectedCustomerId !==
              this.previousSession?.selectedCustomerId
          ) {
            this.previousSession = session;
            return this.getCoveoToken$();
          }

          if (localStorage.getItem(COVEO_TOKEN_LOCAL_STORAGE_KEY) === null) {
            return this.getCoveoToken$();
          } else {
            return of(null);
          }
        }),
      )
      .subscribe();
  }

  teardown() {
    this.coveoTokenSubscription$?.unsubscribe();
    localStorage.removeItem(COVEO_TOKEN_LOCAL_STORAGE_KEY);
  }

  initializeCommerceEngine(coveoToken: string) {
    this.coveoCommerceEngine = buildCommerceEngine({
      configuration: {
        accessToken: coveoToken,
        renewAccessToken: this.getRefreshToken.bind(this),
        organizationId: `${environment.coveoOrganizationId}`,
        analytics: {
          trackingId: 'us_foods',
          enabled: true
        },
        context: {
          country: 'US',
          currency: 'USD',
          language: 'en',
          view: { url: 'url' },
        },
      },
    });
    this.commerceEngineSubject$.next(this.coveoCommerceEngine);
  }

  getSearchEngine$() {
    return this.searchEngineSubject$;
  }

  getCommerceEngine$() {
    return this.commerceEngineSubject$;
  }
}
