<!-- DESKTOP -->
<ng-container *ngIf="(platformService?.isTouchExperience$ | async) === false">
  <div
    data-cy="usf-product-pill-section"
    [ngClass]="hidePill ? 'hide' : 'usf-product-pill'"
    [id]="triggerId"
    (mouseenter)="openProductPillPopover($event)"
    (mouseleave)="closeProductPillPopover()"
  >
    <ng-container [ngTemplateOutlet]="productPill"></ng-container>
  </div>
</ng-container>

<!-- TOUCH -->
<ng-container *ngIf="platformService?.isTouchExperience$ | async">
  <div
    data-cy="usf-product-pill-section"
    [ngClass]="hidePill ? 'hide' : 'usf-product-pill'"
    [id]="triggerId"
  >
    <ng-container [ngTemplateOutlet]="productPill"></ng-container>
  </div>
</ng-container>

<!-- PRODUCT PILL TEMPLATE -->
<ng-template #productPill>
  <ion-icon
    *ngIf="iconUrl && type === chipType.image"
    [attr.data-cy]="'usf-product-pill-image-icon-' + iconUrl"
    class="product-pill__image-icon"
    [src]="iconUrl"
    [style.cursor]="clickable ? 'pointer' : 'default'"
    [style.height.rem]="iconConfig?.iconHeight"
    [style.width.rem]="iconConfig?.iconWidth"
    (click)="onProductPillClick($event)"
  ></ion-icon>

  <img
    *ngIf="iconUrl && type === chipType.contractImage"
    alt=""
    class="product-pill__contract-image"
    [attr.data-cy]="'usf-product-pill-contract-image-icon-' + iconUrl"
    [src]="iconUrl"
    [style.cursor]="clickable ? 'pointer' : 'default'"
    (error)="onImageLoadError()"
    (click)="onProductPillClick($event)"
  />

  <span
    *ngIf="type !== chipType.image && type !== chipType.contractImage"
    data-cy="usf-product-pill-text"
    [ngClass]="{
      'bold-red-text': type === chipType.boldRed,
      'is-claim': type === chipType.claim,
      'selected-facets': type === chipType.selectedFilter,
      'contract': type === chipType.contract,
    }"
    [style.color]="type === chipType.customerPill ? textColor : ''"
    [style.background-color]="
      type === chipType.customerPill ? backgroundColor : ''
    "
    [style.cursor]="clickable ? 'pointer' : 'default'"
    (click)="onProductPillClick($event)"
  >
    <ion-icon
      *ngIf="(iconConfig?.name || iconConfig?.iconUrl) && iconConfig?.leftIcon"
      [attr.data-cy]="'usf-product-pill-icon-left-' + iconConfig?.name"
      [name]="iconConfig?.name"
      [src]="iconConfig?.iconUrl"
      [style.color]="iconConfig?.color"
      [style.cursor]="clickable ? 'pointer' : 'default'"
      [style.height.rem]="iconConfig?.iconHeight"
      [style.width.rem]="iconConfig?.iconWidth"
    ></ion-icon>
    <ng-content></ng-content>
    <ion-icon
      *ngIf="(iconConfig?.name || iconConfig?.iconUrl) && iconConfig?.rightIcon"
      [attr.data-cy]="'usf-product-pill-icon-right-' + iconConfig?.name"
      [name]="iconConfig?.name"
      [src]="iconConfig?.iconUrl"
      [style.color]="iconConfig?.color"
      [style.height.rem]="iconConfig?.iconHeight"
      [style.width.rem]="iconConfig?.iconWidth"
    ></ion-icon>
  </span>
</ng-template>

<!-- CUSTOMER PILL INLINE POPOVER -->
<ng-container
  *ngIf="
    popoverOptions?.inline &&
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-popover
    [trigger]="triggerId"
    reference="trigger"
    triggerAction="hover"
    alignment="center"
    class="customer-pill-popover"
    dismissOnSelect="false"
    showBackdrop="true"
    backdropDismiss="true"
    mode="ios"
    data-cy="inline-popover-desktop"
  >
    <ng-template>
      <ng-container *ngTemplateOutlet="inlinePopoverContent"></ng-container>
    </ng-template>
  </ion-popover>
</ng-container>

<ng-container
  *ngIf="popoverOptions?.inline && platformService?.isTouchExperience$ | async"
>
  <ion-popover
    [trigger]="triggerId"
    reference="trigger"
    triggerAction="click"
    alignment="center"
    class="customer-pill-popover"
    dismissOnSelect="false"
    showBackdrop="true"
    backdropDismiss="true"
    mode="ios"
    data-cy="inline-popover-touch"
  >
    <ng-template>
      <ng-container *ngTemplateOutlet="inlinePopoverContent"></ng-container>
    </ng-template>
  </ion-popover>
</ng-container>

<ng-template #inlinePopoverContent>
  <div
    [innerHTML]="popoverOptions?.inlinePopoverContent ?? ''"
    data-cy="inline-popover-content"
  ></div>
</ng-template>
