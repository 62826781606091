<ion-header
  data-cy="super-user-customers-modal-header"
  class="ion-no-border"
  [ngClass]="
    platform !== platformEnum.desktop
      ? platform === platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons *ngIf="platform === platformEnum.desktop" slot="end">
      <ion-button
        data-cy="super-user-customers-modal-close-button"
        (click)="handleDismiss()"
      >
        <ion-icon
          data-cy="super-user-customers-modal-close-button-icon"
          name="close-outline"
          slot="icon-only"
        ></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-label data-cy="super-user-customers-modal-header-label">
      {{ 'i18n.header.selectCustomer' | translate }}
    </ion-label>

    <ion-searchbar
      data-cy="super-user-customers-modal-searchbar"
      class="search"
      mode="md"
      placeholder="{{ 'i18n.header.search' | translate }}"
      (ionClear)="resetModalData()"
      (keydown.enter)="search($event)"
    ></ion-searchbar>
  </ion-toolbar>
</ion-header>

<ng-container *ngIf="searchKey">
  <ion-content
    *ngIf="
      customerService.showCustomersDataLoadingSpinner$ | async;
      else customerSearchResults
    "
    data-cy="super-user-customers-modal-content"
  >
    <div data-cy="super-user-customers-modal-loading" class="spinner">
      <ion-spinner
        data-cy="super-user-customers-modal-spinner"
        name="circles"
      ></ion-spinner>
    </div>
  </ion-content>

  <ng-template #customerSearchResults>
    <ng-container
      *ngIf="superUserCustomersModalVM$ | async as superUserCustomersModalVM"
    >
      <ion-content
        data-cy="super-user-customers-modal-content"
        [ngClass]="
          platform !== platformEnum.desktop
            ? platform === platformEnum?.tablet
              ? 'ion-content-modal-tablet'
              : 'ion-content-modal-mobile'
            : 'ion-content-modal-desktop'
        "
      >
        <ion-list
          *ngIf="
            superUserCustomersModalVM.customerSelectionOptions.length;
            else noCustomersFound
          "
          data-cy="super-user-customers-modal-options-list"
          (click)="handleSuperUserCustomerSelected()"
        >
          <ion-radio-group [(ngModel)]="selectedCustomer">
            <app-super-user-customer-option
              *ngFor="
                let customer of superUserCustomersModalVM.customerSelectionOptions
                  | paginate
                    : {
                        itemsPerPage: 15,
                        currentPage,
                        totalItems:
                          superUserCustomersModalVM.customerSelectionOptions
                            .length,
                      };
                let i = index
              "
              [platform]="platform"
              [customer]="customer"
              [customerOptionIndex]="i"
            >
              <div
                *ngIf="superUserFavoriteCustomerFeatureFlag$ | async"
                class="favorite-toggle-wrapper"
                (click)="toggleIsFavorite($event, customer)"
              >
                <ion-icon
                  [name]="customer.isFavorite ? 'heart' : 'heart-outline'"
                ></ion-icon>
              </div>
            </app-super-user-customer-option>
          </ion-radio-group>
        </ion-list>

        <ng-template #noCustomersFound>
          <div
            data-cy="super-user-customers-modal-no-results"
            class="no-results"
          >
            <p data-cy="super-user-customers-modal-no-results-text">
              {{ 'i18n.header.noCustomersFound' | translate }}
            </p>
          </div>
        </ng-template>
      </ion-content>

      <pagination-controls
        *ngIf="superUserCustomersModalVM.customerSelectionOptions.length > 15"
        data-cy="super-user-customers-modal-pagination-controls"
        class="pagination-controls"
        previousLabel="{{ 'i18n.common.prev' | translate }}"
        nextLabel="{{ 'i18n.common.next' | translate }}"
        (pageChange)="changePage($event)"
      >
      </pagination-controls>
    </ng-container>
  </ng-template>
</ng-container>
