export interface Tile {
  type?: string;
  id: string;
  hidden?: boolean;
  title?: string;
  newsTitle?: string;
  textBody?: string;
  newsBody?: string;
  textButton?: string;
  newsButton?: string;
  backgroundImage?: string;
  path?: string[];
  deliveryDate?: string;
  deliveryTime?: string;
  dtDescription?: string;
  recommendedContent?: RecommendedContent;
  isDelivered?: boolean;
  lists?: any[];
  newsUrl?: string;
  hideEtaRange?: boolean;
}

export class RecommendedContent {
  noteId?: string;
  unreadCount?: number;
  recommendedTitle?: string;
  recommendedDate?: string | Date;
  recommendedDescription?: string;
}
