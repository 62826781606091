<div
  class="warning-popover-inner"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'tablet'
        : 'mobile'
      : 'desktop'
  "
>
  <div
    class="ion-text-end close-modal-icon"
    *ngIf="
      platformService?.platformType ===
        platformService?.platformEnum?.desktop ||
      platformService?.platformType === platformService?.platformEnum?.tablet
    "
  >
    <ion-icon
      (click)="dismiss()"
      data-cy="warning-popover-close-button"
      name="close"
    ></ion-icon>
  </div>
  <div class="warning-popover-content">
    <div
      class="ion-text-end close-modal-icon"
      *ngIf="
        platformService?.platformType === platformService?.platformEnum?.mobile
      "
    >
      <ion-icon
        (click)="dismiss()"
        data-cy="warning-popover-mobile-close-button"
        name="close"
      ></ion-icon>
    </div>
    <img
      class="alert-icon-border"
      src="../../../../../assets/images/alert-orange.svg"
    />

    <div class="warning-text">
      <h3 data-cy="warning-popover-description-text">
        {{ description }}
      </h3>

      <div class="warning-popover-footer-button">
        <ion-button
          class="usf-fill-orange-dsktp-tblt-modal-button"
          (click)="dismiss()"
          data-cy="warning-popover-dismiss-button"
        >
          {{ 'i18n.common.continue' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</div>
