import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizeWithDots',
})
export class CapitalizeWithDotsPipe implements PipeTransform {
  transform(value: string, targetString: string): string {
    if (!value || !targetString) {
      return value;
    }

    const regex = new RegExp(targetString, 'gi');
    return value.replace(regex, match => {
      const capitalized = match.toUpperCase().split('').join('.') + '.';
      return capitalized;
    });
  }
}
