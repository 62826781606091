<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$ | async) === false"
      slot="end"
    >
      <ion-button
        id="close-modal-btn"
        data-cy="close-delete-inventory-item-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content
  data-cy="modal-prompt-area-text-delete-inv-item"
  class="simple-action-modal_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <div class="content">
    <div class="delete-modal-text">
      <div class="header-text" data-cy="delete-inventory-item-label">
        {{
          'i18n.inventory.inventoryWorksheet.deleteInventoryItemsHeader'
            | translate
        }}
        <span class="count-text" data-cy="delete-inventory-item-slected-count">
          {{ inventory?.inventoryOptions?.selections?.length - 0
          }}{{
            'i18n.inventory.inventoryWorksheet.deleteInventoryItemsCount'
              | translate
          }}
        </span>
        {{
          'i18n.inventory.inventoryWorksheet.deleteInventoryItemsSelected'
            | translate
        }}
      </div>

      <p data-cy="confirm-delete-inventory-item-label">
        {{
          'i18n.inventory.inventoryWorksheet.deleteInventoryItemText'
            | translate
        }}&nbsp;
      </p>
    </div>
  </div>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="no-modal-btn"
        data-cy="no-delete-inventory-item-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-outline-green-dsktp-tblt-modal-button'
            : 'usf-outline-green-mobile-modal-button'
        "
        (click)="dismissModal()"
      >
        {{
          'i18n.inventory.inventoryWorksheet.deleteIventoryModalCancel'
            | translate
        }}
      </ion-button>
      <ion-button
        id="yes-modal-btn"
        data-cy="yes-delete-inv-item-modal-button"
        [ngClass]="
          platformService?.platformType !==
          platformService?.platformEnum?.mobile
            ? 'usf-fill-green-dsktp-tblt-modal-button'
            : 'usf-fill-green-mobile-modal-button'
        "
        (click)="dispatchAction(InventoryActions.delete)"
      >
        {{
          'i18n.inventory.inventoryWorksheet.deleteIventoryModalConfirm'
            | translate
        }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
