import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Customer, CustomerSummary } from '@usf/customer-types/customer';
import { BehaviorSubject, filter, map, Observable } from 'rxjs';
import { CUSTOMER_ACTIONS } from '../../ngrx-customer/store';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  isSuperUserCustomersModalOpen$ = new BehaviorSubject<boolean>(false);
  showCustomersDataLoadingSpinner$ = new BehaviorSubject<boolean>(false);
  filteredCustomerSummaries$ = new BehaviorSubject<CustomerSummary[]>([]);
  retrievedCustomer$ = new BehaviorSubject<Customer>({} as Customer);

  constructor(private store: Store) {}

  searchCustomers(searchKey: string) {
    this.store.dispatch(
      CUSTOMER_ACTIONS.searchCustomers({
        searchKey,
      }),
    );
  }

  setIsSuperUserCustomersModalOpen(isOpen: boolean) {
    this.isSuperUserCustomersModalOpen$.next(isOpen);
  }

  setShowCustomersDataLoadingSpinner(isOpen: boolean) {
    this.showCustomersDataLoadingSpinner$.next(isOpen);
  }

  setFilteredCustomerSummaries(customerSummaries: CustomerSummary[]) {
    this.filteredCustomerSummaries$.next(customerSummaries);
  }

  setRetrievedCustomer(customer: any) {
    this.retrievedCustomer$.next(customer);
  }

  getRetrievedCustomer$(): Observable<Customer> {
    return this.retrievedCustomer$.asObservable();
  }

  getFilteredCustomerSummaries$(): Observable<CustomerSummary[]> {
    return this.filteredCustomerSummaries$.asObservable();
  }

  getCustomersDataLoadingSpinner$(): Observable<boolean> {
    return this.showCustomersDataLoadingSpinner$.asObservable();
  }
}
