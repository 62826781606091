import { createSelector } from '@ngrx/store';
import { Notification } from '@usf/alert-types';
import { notificationSelectors, NotificationState } from '@usf/ngrx-alerts';

export const selectNotifications = createSelector(
  notificationSelectors.selectNotificationContextState,
  (
    notificationState: NotificationState,
  ): { notifications: Notification[]; loading: boolean } => {
    const notifications = (notificationState?.ids as Array<string | number>)
      ?.map((id: string | number) => {
        return notificationState.entities[id];
      })
      ?.sort(
        (a, b) => new Date(b.addDtm).getTime() - new Date(a.addDtm).getTime(),
      );
    return {
      notifications: notifications,
      loading: notificationState?.loading,
    };
  },
);

export const selectAlertBatchIdByDetailsId = (detailsId: string) =>
  createSelector(
    notificationSelectors.selectNotificationContextState,
    (notificationState: NotificationState) => {
      if (!notificationState) {
        return null;
      }
      const notifications = (notificationState.ids as string[]).map(
        id => notificationState.entities[id],
      );
      const matchingNotification = notifications.find(notification =>
        notification?.alertContext?.some(
          ctx => ctx.key === 'detailsId' && ctx.value === detailsId,
        ),
      );
      return matchingNotification?.alertBatchId ?? null;
    },
  );
