import { NgRxPricingLibConfig } from '@usf/ngrx-pricing';
import { environment } from 'src/environments/environment';

export const NG_RX_PRICING_LIB_CONFIG: NgRxPricingLibConfig = {
  apiUrl: environment.pricingApiUrl,
  webSocketUrl: environment.webSocketUrl,
  timeout: environment.pricingTimeout,
  priceBatchSize: environment.priceBatchSize,
  pricingSeconds: environment.pricingSeconds,
};
