<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-header-modal-tablet'
        : 'ion-header-modal-mobile'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons
      *ngIf="(platformService?.isTouchExperience$ | async) === false"
      slot="end"
    >
      <ion-button data-cy="close-product-status-button" (click)="closeModal()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="product-detail-status-label">
      {{ 'i18n.productDetail.status.productDetailStatus' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="product-detail-status_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? platformService?.platformType === platformService?.platformEnum?.tablet
        ? 'ion-content-modal-tablet'
        : 'ion-content-modal-mobile'
      : 'ion-content-modal-desktop'
  "
>
  <ion-grid *ngIf="displayProductDetailSection">
    <app-product-detail-status-tags-info
      [productDetailStatusData]="productDetailStatusData"
    ></app-product-detail-status-tags-info>

    <section
      *ngIf="productDetailStatusData?.claims?.length > 0 && showProductClaims"
    >
      <p data-cy="product-claim-label">
        {{ 'i18n.productDetail.status.productClaim' | translate }}
      </p>
      <ion-row
        *ngFor="let claim of productDetailStatusData?.claims"
        class="product-claims-row"
      >
        <ion-col size="6" size-xl="2.5" size-lg="3" size-md="4">
          <app-usf-chip [type]="chipTypeEnum.claim">
            {{ claim.name }}
          </app-usf-chip>
        </ion-col>
        <ion-col size="6" size-xl="9.5" size-lg="9" size-md="8">
          <span data-cy="product-claim-text">{{
            getClaimDescription(claim)
          }}</span>
        </ion-col>
      </ion-row>
    </section>
  </ion-grid>
</ion-content>
