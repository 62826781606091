<div class="typeahead-search">
  <ion-searchbar
    #searchCatalog
    class="search"
    animated="true"
    autocapitalize="none"
    mode="md"
    placeholder="{{ 'i18n.header.searchCatalog' | translate }}"
    [debounce]="typeaheadDebounce"
    (ionBlur)="handleInputBlur($event)"
    (ionInput)="handleInputChange($event)"
    (ionFocus)="handleInputFocus($event)"
    (keydown.enter)="handleSearch(searchCatalog)"
    (keydown)="handleArrowKey($event)"
    data-cy="typeahead-search-input"
  ></ion-searchbar>
  <div
    *ngIf="
      typeaheadVisible && !typeaheadNoResults && !isNewSearchEnabled;
      else newTypeahead
    "
    class="typeahead"
  >
    <div *ngFor="let group of customTypeAheadGroup">
      <h2 [attr.data-cy]="'group-' + group.name">{{ group.name }}</h2>
      <ul>
        <li
          *ngFor="let item of group.items; let i = index"
          (click)="handleItemClick(item, group.name, i + 1)"
          [attr.data-cy]="'click-item-' + item.description"
          [class.active]="item.isActive"
          class="active"
        >
          {{ item.description }}
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #newTypeahead>
  <div *ngIf="typeaheadVisible" class="newtypeahead">
    <div class="left-section">
      <div class="suggested-searches">
        <h3 class="h3-tile">Suggested Searches</h3>
        <ul class="ul-cls">
          <li
            *ngFor="let item of suggestedSearchTypeahead; let i = index"
            (click)="handleSearchBySuggestions(item)"
            [attr.data-cy]="'click-item-' + item"
            [class.active]="false"
            class="active"
          >
            <div [innerHTML]="item.highlightedValue">
              {{ item.displayValue }}
            </div>
          </li>
        </ul>
      </div>
      <div class="brands">
        <h3 class="h3-tile">Brands</h3>
        <ul class="ul-cls">
          <li
            *ngFor="let item of brandsTypeahead; let i = index"
            (click)="handleBrandsItemClick(item)"
            [attr.data-cy]="'click-item-' + item.rawValue"
            [class.active]="false"
            class="active"
          >
            {{ item.rawValue }}
          </li>
        </ul>
      </div>
    </div>

    <div class="divider"></div>

    <div class="right-section">
      <div class="products-header">
        <h3 class="h3-tile">Products</h3>
        <ion-button
          (click)="handleSearch(searchCatalog)"
          class="usf-green-link-button"
          expand="block"
        >
          View All
        </ion-button>
      </div>

      <ul class="ul-cls">
        <li
          *ngFor="let item of productsTypeahead; let i = index"
          (click)="handleProductSuggestionClick(item, i + 1)"
          [attr.data-cy]="'click-item-' + item.ec_product_id"
          [class.active]="false"
          class="active"
        >
          <div [innerHTML]="item.productName"></div>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
