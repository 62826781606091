import { Injectable } from '@angular/core';
import { SelectedCustomerState } from '@panamax/app-state';
import { Customer } from '@usf/customer-types';
import moment from 'moment';
import { MyOrdersAnalyticsService } from '../tracking/analytics/my-orders-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class MyOrdersService {
  constructor(private myOrdersAnalytics: MyOrdersAnalyticsService) {}

  static getNextDeliveryDate = (customer: Customer | SelectedCustomerState) => {
    for (let i = 1; i <= 7; i++) {
      let deliveryDay = moment().add(i, 'days');
      switch (deliveryDay.day()) {
        case 1:
          if (customer.monDelivery) return deliveryDay.toDate();
          break;
        case 2:
          if (customer.tueDelivery) return deliveryDay.toDate();
          break;
        case 3:
          if (customer.wedDelivery) return deliveryDay.toDate();
          break;
        case 4:
          if (customer.thuDelivery) return deliveryDay.toDate();
          break;
        case 5:
          if (customer.friDelivery) return deliveryDay.toDate();
          break;
        case 6:
          if (customer.satDelivery) return deliveryDay.toDate();
          break;
        case 7:
          if (customer.sunDelivery) return deliveryDay.toDate();
          break;
      }
    }
  };

  static getDateKey = (date: Date) => {
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();
    return month + '/' + day + '/' + year;
  };

  static customerHasRecentOrder = (
    customer: Customer | SelectedCustomerState,
  ) => {
    if (!customer?.lastOrderedDate) return false;

    const now = moment.utc().utcOffset(0);
    const lastOrderDate = moment.utc(customer.lastOrderedDate).utcOffset(0);
    const daysBetweenDates = moment.duration(now.diff(lastOrderDate));

    if (daysBetweenDates.asDays() < 30) return true;

    return false;
  };

  trackPageLoad(filters: string): void {
    this.myOrdersAnalytics.trackPageLoad(filters);
  }

  trackQuickFilterClick(filters: string): void {
    this.myOrdersAnalytics.trackQuickFilterClick(filters);
  }
}
