export const hideOGProfile = 'HIDE_OG';
export const hideContractedSugesstiveSellSummaryProfile =
  'hideContractedSugesstiveSellSummary';
export const MSLRestrictionOverride = 'MSLRestrictionOverride';
export const HideInvoiceAndPayments = 'HideInvoiceAndPayments';
export const PayInvoiceUser = 'PayInvoiceUser';
export const hideSupplierUnavailable = 'HideSupplierUnavailable';
export const hideInventoryOutOfStockYellowModule =
  'HideInventoryOutOfStockYellowModule';
export const SuperUser = 'SUPER_USER';
export const AccessRole = 'ACCESS_ROLE';
export const HideBusinessAnalytics = 'HideBusinessAnalytics';
